const ACTIVE_STATUS = 'active';
const APPROVED_STATUS = 'approved';
const CLOSED_STATUS = 'closed';
const INACTIVE_STATUS = 'inactive';
const PENDING_STATUS = 'pending';
const REJECTED_STATUS = 'rejected';
const ACCEPTED_STATUS = 'accepted';
const COMPLETED_STATUS = 'complete';
const FAILURE_STATUS = 'fail';

const LOG_OPERATION_DELETE = 'delete';
const LOG_OPERATION_INSERT = 'insert';
const LOG_OPERATION_UPDATE = 'update';

const NOTIFICATION_TYPE_ERROR = 'error';
const NOTIFICATION_TYPE_NOTIFICATION = 'notification';

const SAT_ACTIVE = 'active';
const SAT_CANCELLED = 'cancelled';

const USER_CONFIRMED = 'CONFIRMED';
const USER_FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD';
const USER_UNCONFIRMED = 'UNCONFIRMED';
const USER_ERROR_NOT_FOUND = 'UserNotFoundException';

export const accountStatusColor = {
  [APPROVED_STATUS]: 'success',
  [CLOSED_STATUS]: 'secondary',
  [PENDING_STATUS]: 'warning',
  [REJECTED_STATUS]: 'error',
};

export const nominatedCardStatus = {
  [COMPLETED_STATUS]: 'success',
  [PENDING_STATUS]: 'warning',
  [FAILURE_STATUS]: 'error',
};

export const logOperationColor = {
  [LOG_OPERATION_DELETE]: 'error',
  [LOG_OPERATION_INSERT]: 'success',
  [LOG_OPERATION_UPDATE]: 'warning',
};

export const notificationTypeStatusColor = {
  '': 'secondary',
  [NOTIFICATION_TYPE_ERROR]: 'error',
  [NOTIFICATION_TYPE_NOTIFICATION]: 'success',
};

export const peopleStatusColor = {
  [INACTIVE_STATUS]: 'info',
  [PENDING_STATUS]: 'warning',
  [ACTIVE_STATUS]: 'success',
  [REJECTED_STATUS]: 'error',
  [ACCEPTED_STATUS]: 'secondary',
};

export const satStatusColor = {
  [SAT_ACTIVE]: 'success',
  [SAT_CANCELLED]: 'error',
};

export const userStatusColor = {
  [USER_CONFIRMED]: 'success',
  [USER_FORCE_CHANGE_PASSWORD]: 'warning',
  [USER_UNCONFIRMED]: 'error',
  [USER_ERROR_NOT_FOUND]: 'error',
};
