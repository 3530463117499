import { lazy } from 'react';

import AuthGuard from 'components/AuthGuard';
import DashboardLayout from 'layouts/DashboardLayout';

import logsRoutes from './logs';
import notificationsRoutes from './notifications';
import peopleRoutes from './people';
//import pldRoutes from './pld';
import productsRoutes from './products';
import reportsRoutes from './reports';
import settingsRoutes from './settings';
import cardsRoutes from './cards';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const dashboardRoutes = {
  path: '/',
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: '/dashboard',
      permission: 'dashboard.select.listDashboard',
      component: lazy(() => import('views/Dashboard')),
    },
    ...logsRoutes('/logs'),
    ...notificationsRoutes('/notifications'),
    ...peopleRoutes('/people'),
    //...pldRoutes('/pld'),
    ...productsRoutes('/products'),
    ...reportsRoutes('/reports'),
    ...settingsRoutes('/settings'),
    ...cardsRoutes('/cards'),
    error404,
  ],
};

export default dashboardRoutes;
