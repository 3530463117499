import { lazy } from 'react';

const reportsRoutes = (parentPath) => [
  {
    exact: true,
    path: `${parentPath}`,
    permission: 'reports.select.listReports',
    component: lazy(() => import('views/Reports/List')),
  },
  {
    exact: true,
    path: `${parentPath}/add`,
    permission: 'reports.insert.addReports',
    component: lazy(() => import('views/Reports/Edit')),
  },
  {
    exact: true,
    path: `${parentPath}/:reportId`,
    component: lazy(() => import('views/Reports/Edit')),
  },
  {
    exact: true,
    path: `${parentPath}/system/:name`,
    component: lazy(() => import('views/Reports/System')),
  },
];

export default reportsRoutes;
