import {
  DeviceHub as DeviceHubIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  GraphicEq as GraphicEqIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
} from '@material-ui/icons';

import navTemplateItems from './templates';
import navUsersItems from './users';

const items = [
  {
    href: '/settings/users',
    icon: SupervisedUserCircleIcon,
    items: navUsersItems,
    permissions: [
      'groups.select.listGroups',
      'roles.select.listRoles',
      'users.select.listUsers',
    ],
    title: 'Accesos',
  },
  {
    href: '/settings/campaigns',
    icon: GraphicEqIcon,
    permissions: 'campaigns.select.listCampaigns',
    title: 'Campañas',
  },
  {
    href: '/settings/flows',
    icon: DeviceHubIcon,
    permissions: 'flows.select.getFlows',
    title: 'Flujos',
  },
  {
    href: '/settings/templates',
    icon: FileCopyOutlinedIcon,
    items: navTemplateItems,
    permissions: [
      'clientTypes.select.listClientTypes',
      'notificationTypes.select.listNotificationTypes',
      'productTypes.select.listProductTypes',
    ],
    title: 'Plantillas',
  },
];

export default items;
