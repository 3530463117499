import { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Cancel as CancelIcon,
  CloudDownload as CloudDownloadIcon,
  CloudUpload as CloudUploadIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Delete as DeleteIcon,
  PostAdd as PostAddIcon,
} from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import { Alert } from '@material-ui/lab';

import { Modal } from 'components';
import { MIME_TYPES } from 'constants/files';
import { getSignedUrl, upload } from 'utils/aws/s3';

import { DEFAULT_PROPS, PROP_TYPES } from './contants';
import useStyles from './style';

const EXT_IMAGES = MIME_TYPES
  .filter(_ => _.type === 'image')
  .map(_ => _.name) || [];

function DocumentControl(props) {
  const {
    error,
    events = {},
    label,
    name: controlName,
    placeholder,
    onChange,
    settings: {
      readOnly = false,
      route = 'tmp/',
    } = {},
  } = props;
  let { value = [] } = props;

  if (typeof value === 'string') {
    try {
      value = JSON.parse(value) || [];
    } catch (e) {
      value = [];
    }
  }

  const classes = useStyles();
  const [documents, setDocuments] = useState(value || []);
  const [hasError, setHasError] = useState();
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState();
  const [nameFile, setNameFile] = useState();
  const [open, setOpen] = useState();
  const [signedDocuments, setSignedDocuments] = useState([]);

  const handlePreview = async function (document) {
    const signedUrl = await getSignedUrl(document.url, 120);
    const extension = (document.url.split('.').pop() || '').toLowerCase();
    const mimeType = MIME_TYPES.find(_ => _.name === extension);

    setModal({
      canFullScreen: true,
      open: true,
      title: 'Vista previa',
      download: true,
      data: {
        ...document,
        mimeType,
        signedUrl,
      },
    });
  };

  const handleShowModal = (item) => {
    setNameFile('');
    setFilesToUpload([]);

    setModal({
      open: true,
      title: 'Cargar archivo',
      download: false,
      data: {},
      item,
    });
  };

  const handleFileSave = async () => {
    if (!nameFile) {
      addError('Debes escribir el nombre del archivo');

      return;
    }

    setLoading(true);
    const uploaded = [];

    for await (const fileToUpload of filesToUpload) {
      const ext = (fileToUpload.name.split('.').pop() || '').toLowerCase();
      const s3File = await upload(
        `${route}${new Date().getTime()}.${String(Math.random()).substring(
          2,
          7,
        )}${ext ? `.${ext.toLowerCase()}` : ''}`,
        fileToUpload,
      );

      uploaded.push({
        url: s3File.Location,
        uploaded: new Date().getTime(),
        active: true,
        name: `${nameFile || ''}${filesToUpload.length > 1 ? (` ${uploaded.length + 1}`) : ''}`,
      });
    };

    setDocuments([
      ...documents,
      ...uploaded,
    ]);
    setHasError('');
    setModal({
      open: false,
    });
  };

  const handleShowModalDelete = (index) => {
    setOpen({
      show: true,
      index,
    });
  };

  const handleDelete = (index) => {
    setOpen({
      show: false,
      index,
    });
    const tmpImg = [...documents.filter(_ => _.active)];
    tmpImg[index].active = false;

    setDocuments(tmpImg);
  };

  const handleDownload = async function (image) {
    const url = await getSignedUrl(image.url, 120);

    window.open(url);
  };

  const loadSigned = async () => {
    const tmpDoc = [];
    if (documents?.length > 0) {
      for await (const document of documents.filter(_ => _.active)) {
        const signedUrl = await getSignedUrl(document.url, 120);
        tmpDoc.push(signedUrl);
      }
      setSignedDocuments(tmpDoc);
    }
  };

  const addError = function (msg = '') {
    setHasError(msg);

    document.getElementById('modalInputFileName').focus();
  };

  useEffect(() => {
    const target = {
      name: controlName,
      value: documents,
    };
    onChange({
      target,
    });

    if (events?.onChange) {
      events.onChange(target);
    }

    loadSigned();
    setLoading(false);
  }, [documents]);

  return (
    <>
      <Dialog
        open={open?.show}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'¿Esta seguro de eliminar el archivo?'}
        </DialogTitle>
        <DialogActions>
          <Button
            color="secondary"
            children={'Cancelar'}
            onClick={() => setOpen({
              show: false,
            })}
            variant="contained"
          />
          <Button
            autoFocus
            color="primary"
            children={'Aceptar'}
            onClick={() => handleDelete(open?.index)}
            variant="contained"
          />
        </DialogActions>
      </Dialog>

      <Modal
        canFullScreen={modal?.canFullScreen}
        open={modal?.open}
        title={modal?.title}
        events={{
          onClose: !loading ? () => {
            setHasError('');
            setModal();
          } : undefined,
        }}
        actions={[
          {
            color: 'secondary',
            icon: <CloudDownloadIcon />,
            label: 'Descargar',
            hidden: !modal?.download,
            fn: () => handleDownload(modal?.data),
          },
          {
            color: 'secondary',
            disabled: !filesToUpload.length || loading,
            icon: loading ? <CircularProgress size={20} /> : <CloudUploadIcon />,
            label: 'Cargar',
            hidden: modal?.download,
            fn: handleFileSave,
          },
        ]}
      >

        {modal?.data?.mimeType?.visor === 'image' && (
          <img alt="" width="100%" src={modal?.data?.signedUrl} />
        )}

        {modal?.data?.mimeType?.visor === 'document' && (
          <iframe
            frameborder="0"
            height="100%"
            src={`https://docs.google.com/gview?url=${encodeURIComponent(modal?.data?.signedUrl)}&embedded=true`}
            title="Visor"
            width="100%"
          />
        )}
        {modal?.data?.mimeType?.visor === 'code' && (<>
          Vista previa no disponible
        </>)}

        {modal?.item && (<Grid container>
          <input
            multiple
            id={`file_${modal?.item?.field}`}
            name={modal?.item?.filename}
            type="file"
            style={{
              display: 'none',
            }}
            onChange={({ target }) => {
              const count = target.files.length;
              const files = [];
              for (let i = 0; i < count; i++) {
                files.push(target.files[i]);
              }

              setFilesToUpload([
                ...filesToUpload,
                ...files,
              ]);
            }}
          />
          <Grid item md={12}>

            {hasError ?
              (<Alert
                children={hasError}
                onClose={() => addError()}
                severity="error"
              />)
              : (<Typography
                children="Escriba el nombre con el que se identificaran los archivos:"
                variant="body2"
              />)}
            <TextField
              fullWidth={true}
              id="modalInputFileName"
              name={'file_name'}
              onChange={({ target }) => setNameFile(target.value)}
              required
              size='small'
              type={'text'}
              value={nameFile}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12}>
            <Box mt={1} textAlign="center">
              <Tooltip title="Agregar archivos">
                <Button
                  children="Seleccionar archivos"
                  color="primary"
                  disabled={loading}
                  onClick={() => {
                    document.getElementById(`file_${modal?.item?.field}`).click();
                  }}
                  startIcon={<PostAddIcon />}
                  size="medium"
                  variant="contained"
                />
              </Tooltip>
            </Box>
          </Grid>

          {filesToUpload.length > 0 && <Grid item md={12}>
            <Paper
              style={{
                marginTop: 10,
              }} elevation={2}
            >
              <List dense>
                {filesToUpload.map((fileItem, fileIndex) => (<ListItem
                  button
                  key={fileIndex}
                >
                  <ListItemAvatar
                    children={<Avatar children={<InsertDriveFileIcon />} />}
                  />
                  <ListItemText
                    primary={fileItem.name}
                    secondary={<>
                      <b>{Math.round(fileItem.size / 10.24, 2) / 100} KB</b>
                      {' '}{fileItem.type}
                    </>}
                  />
                  {!loading && (<ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <CancelIcon
                        onClick={() => {
                          setFilesToUpload(filesToUpload.filter((_, i) => i !== fileIndex));
                        }}
                        style={{
                          color: red[500],
                        }}
                      />
                    </IconButton>
                  </ListItemSecondaryAction>)}
                </ListItem>))}
              </List>
            </Paper>
          </Grid>}

        </Grid>)}
      </Modal>

      <div className={classes.rootImage}>
        <fieldset style={{
          borderRadius: 5,
          borderColor: error ? 'red' : undefined,
        }}>
          {label && <legend style={{
            marginLeft: 8,
          }}>
            <Typography color="textSecondary" variant="caption">
              <Box style={{
                marginLeft: 5,
                marginRight: 5,
              }}>
                {label}
              </Box>
            </Typography>
          </legend>}
          {placeholder && <Typography color="textPrimary" variant="caption">
            <Box
              children={placeholder}
              style={{
                marginLeft: 5,
                marginRight: 5,
              }}
            />
          </Typography>}
          {error && <Typography color="error" variant="caption">
            <Box
              children={error}
              style={{
                marginLeft: 5,
                marginRight: 5,
              }}
            />
          </Typography>}
          <ImageList
            alignItems="center"
            className={classes.imageList}
            cols={5}
            direction="row"
            justifyContent="center"
          >
            {!readOnly && <IconButton
              className={classes.button}
              onClick={() => handleShowModal(value)}
            >
              <Tooltip
                children={<SvgIcon children={<AddIcon />} />}
                title="Agregar archivo"
              />
            </IconButton>}
            {
              documents.filter(_ => _.active).map((doc, index) => {
                const ext = doc.url.split(/[\s.]+/).pop();

                return (
                  <ImageListItem key={doc.name} style={{
                    padding: 5,
                    minWidth: 120,
                    cursor: 'pointer',
                  }}>
                    {EXT_IMAGES.includes(ext) ?
                      (<img
                        alt={doc.name}
                        src={signedDocuments[index]}
                        onClick={() => handlePreview(doc)}
                      />) :
                      (<SvgIcon
                        fontSize="large"
                        children={<InsertDriveFileIcon />}
                        onClick={() => handlePreview(doc)}
                        style={{
                          minHeight: 126,
                          width: '100%',
                        }}
                      />)
                    }
                    <ImageListItemBar
                      title={doc.name}
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                      actionIcon={readOnly
                        ? undefined
                        : (<IconButton
                          aria-label={`star ${doc.name}`}
                          onClick={() => handleShowModalDelete(index)}
                        >
                          <DeleteIcon className={classes.title} />
                        </IconButton>)}
                    />
                  </ImageListItem>
                );
              })
            }
          </ImageList>
        </fieldset>
      </div>
    </>
  );
}

DocumentControl.defaultProps = DEFAULT_PROPS;
DocumentControl.propTypes = PROP_TYPES;

export default DocumentControl;
