import PropTypes from 'prop-types';

import imagotipo from 'assets/svg/imagotipo.svg';
import imagotipoDarkH from 'assets/svg/imagotipo-blue-h.svg';
import imagotipoHorizontal from 'assets/svg/imagotipo-horizontal.svg';

function readLogos() {
  let logos;
  try {
    const company = JSON.parse(localStorage.getItem('company'));

    logos = company?.logo || {};
  } catch (e) {
    logos = {};
  } finally {
    return logos;
  }
}

function Logo({ style, variant }) {
  const logos = readLogos();

  const companyLogos = {
    imagotipo: logos.imagotipoHorizontal || imagotipo,
    'imagotipo-horizontal': logos.imagotipoHorizontal || imagotipoHorizontal,
    'imagotipo-horizontal-dark': logos.imagotipoDarkH || imagotipoDarkH,
  };

  return (<img
    alt="Company logo"
    src={companyLogos[variant]}
    style={style}
  />);
}

Logo.defaultProps = {
  company: 'a55',
  variant: 'imagotipo-horizontal-dark',
};

Logo.propTypes = {
  style: PropTypes.object,
  variant: PropTypes.oneOf([
    'imagotipo',
    'imagotipo-horizontal',
    'imagotipo-horizontal-dark',
  ]),
};

export default Logo;
