import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { Divider } from 'components';
import CodeControl from 'components/DynamicForm/controls/CodeControl';

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    verticalAlign: 'top',
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

function KeyValueList({ items }) {
  const classes = useStyles();

  return (
    <Table>
      <TableBody>
        {items && items.map((item, index) => {
          let { label = '', value = '' } = item;

          if (Array.isArray(item)) {
            [label, value] = item;
          }

          if (typeof value === 'object' && !Array.isArray(value) && !value?.props) {
            value = <CodeControl
              disabled
              settings={{
                lang: 'json',
                lineNumbers: false,
              }}
              value={JSON.stringify(value, null, '\t')}
            />;
          }

          return (<>
            <TableRow key={index}>
              {(label !== false) && <TableCell
                children={label}
                className={classes.fontWeightMedium}
              />}
              <TableCell>
                <Typography
                  children={Array.isArray(value)
                    ? `${value.length} elementos`
                    : value}
                  color="textSecondary"
                  variant="body2"
                />
              </TableCell>
            </TableRow>

            {Array.isArray(value) && (value.map((v, i) => {
              let subVal = v;
              if (Array.isArray(subVal)) {
                subVal = (<KeyValueList items={v} />);
              } else if (typeof subVal === 'object' && subVal !== null) {
                subVal = (<KeyValueList items={Object.entries(v)} />);
              }

              return (<TableRow key={i} >
                <TableCell colSpan={2}>
                  <Divider children={`[${i}]`} />
                  {subVal}
                </TableCell>
              </TableRow>);
            }))}
          </>);
        })}
      </TableBody>
    </Table >
  );
}

KeyValueList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  })),
};

export default KeyValueList;
