export const SERVICES = {
  ACCOUNTS: '/private/accounts',
  ACCOUNTS_CARDS: '/private/accounts/cards',
  ACCOUNTS_DESTINATION: '/private/accounts/destination',
  ACCOUNTS_MASSIVE_MOVEMENTS: '/private/accounts/massivemovements',
  ACCOUNTS_MASSIVE_PRODUCTS: '/private/accounts/massive/products',
  ACCOUNTS_MOVEMENTS: '/private/accounts/movements',
  ACCOUNTS_OPERATIONS: '/private/accounts/operations',
  ACCOUNTS_PROCESSOR: '/private/accounts/processor',
  ACCOUNTS_TRANSACTIONS: '/accounts/:account/transactions',
  ACCOUNTS_TABLE: '/private/accounts/table',
  ACCOUNTS_TABLE_IDEAL: '/private/accounts/ideal_table',

  APPS_BLACK_LISTS_PLD: '/marketplace/apps/pld/lists',
  APPS_BLACK_LISTS_CLIENT_PLD: '/marketplace/apps/pld/client',
  APPS_CC_PLD: '/private/marketplace/apps/cc/pdlfp',
  APPS_CREDENCIAL_AUTHORIZATION_BALANCE: '/marketplace/apps/credencial/authorization/balance',
  APPS_CREDENCIAL_SERVICE_CARD_STATUS: '/marketplace/apps/credencial/service_card/consult',
  //APPS_CREDENTIAL_SERVICE_CARD_CHANGE_STATUS_CARD: '/credencial/service_card/change_status_card', <- works?
  APPS_CREDENTIAL_SERVICE_CARD_CHANGE_STATUS: '/marketplace/apps/credencial/service_card/change_status',
  APPS_MAPPING: '/private/apps/mapping',
  APPS_PAGAQUI_TRANSACT_METHOD: '/marketplace/apps/pagaqui/transact/:method',
  APPS_PAGAQUI_PAYMENT_PROCESS: '/marketplace/apps/pagaqui/payment/process',
  APPS_QUERY: '/private/apps/query',
  APPS_TOKEN_GET: '/private/apps/gettokens',
  APPS_TOKEN_SET: '/private/apps/token',
  APPS_TOOLS_MASSIVE_CARDS: '/marketplace/apps/tools/massive/cards',

  CAMPAIGNS: '/private/campaign',
  CAMPAIGNS_COMPARATOR: '/private/campaign/comparators',
  CAMPAIGNS_CONDITIONALS: '/private/campaign/conditionals',
  CAMPAIGNS_CONDITIONALS_FIELDS: '/private/campaign/conditionals/fields',
  CAMPAIGNS_HISTORICAL: '/private/campaign/historical',
  CAMPAIGNS_LINKS: '/private/campaign/links',
  CAMPAIGNS_RESULTS: '/private/campaign/result',
  CAMPAIGNS_TYPES: '/private/campaign/types',

  CARD_TOKEN: '/cards/tokens',

  CATALOGS_FIELDS: '/private/catalog/templates/fields',
  CATALOGS_TEMPLATES: '/private/catalog/public_template/id',
  CATALOGS_ZIPCODE: '/private/codecatalog/code',
  CATALOGS_ROLES: '/private/catalog/roles',

  CC: '/private/cc',

  COMPANY: '/private/company',
  COMPANY_FIELDS: '/private/company/fields',

  CONNECTOR_CORE: '/internal/connector/:company/core',
  CONNECTOR_SET_CLABE: '/internal/connector/:company/setclabe',
  CONNECTOR_FLOW: '/internal/connector/:company/flows/:flow',

  CRONTAB: '/private/crontab',

  DASHBOARD_CHARTS: '/private/dashboard',
  DASHBOARD_CHARTS_DATA: '/private/dashboard/data',
  DASHBOARD_CHARTS_TYPE: '/private/dashboard/charts',

  FINANCIAL_IRR: '/internal/financial/irr',

  FLOWS: '/private/diagrams/flows',
  FLOWS_CLASS: '/private/diagrams/class',
  FLOWS_NODES: '/private/diagrams/nodes',
  FLOWS_TYPES: '/private/diagrams/flows_types',

  GROUPS: '/private/groups',
  GROUPS_HASH: '/private/groups/hash',

  NOTIFICATIONS_ALERTS: '/private/notifications/alerts',
  NOTIFICATIONS_FIELDS: '/private/notifications/get_fields',
  NOTIFICATIONS_TEMPLATES: '/private/notifications/templates',

  PEOPLE: '/private/clients',
  PEOPLE_MASSIVE: '/private/clients/massive',
  PEOPLE_PII: '/private/clients/pii',
  PEOPLE_PII_FILTER: '/private/clients/pii_filter',
  PEOPLE_TEMPLATES: '/private/clients/template',
  PEOPLE_TYPES: '/private/clients/types',
  PEOPLE_TYPES_IFRAME: '/private/clients/types/create_iframe',

  PLD_ACCIONS: '/private/pld/actions',
  PLD_INTERNAL_REPORTS: '/private/pld/internalreports',
  PLD_MONETARY: '/private/pld/monetary',
  PLD_PARAMETERS: '/private/pld/parameters',
  PLD_OPERATION_TYPES: '/private/pld/operationstypes',
  PLD_REPORT_TYPES: '/private/pld/reportstypes',

  PRODUCTS: '/private/products',
  PRODUCTS_OPERATIONS: '/private/products/operations',
  PRODUCTS_OPERATIONS_CATALOG: '/private/products/operations/catalog',
  PRODUCTS_TYPES: '/private/products/types',

  REPORTS: 'private/reports/config',
  REPORTS_DATA: '/private/reports/loan_tape',
  REPORTS_FIELDS: '/private/reports/loan_fields',
  REPORTS_KPI: '/private/reports/kpi',
  REPORTS_GENERATE: '/private/generated_reports',
  REPORTS_STATICS: '/private/static_reports',

  SAT: '/private/sat/data',
  SAT_CFDI: '/internal/load_cfdi',
  SAT_GET_KEY: '/private/sat/keys',
  SAT_KEY: '/internal/sat/sat_key',
  SAT_MONTHLY_AMOUNTS: '/private/sat/monthly_amounts',
  SAT_PARTNERS_PERCENT: 'private/sat/partners_percent',
  SAT_STATUS: '/private/sat/query_status',
  SAT_SYNC: '/internal/sat/sat_cfdi',
  SAT_PRIVATE_STATEMENTS: '/private/sat/statements',
  SAT_INTERNAL_STATEMENTS: '/internal/sat/statements',

  SIMULATOR: '/private/demo/table',

  USERS: '/private/users',
  USERS_ADD: '/users/add',
  USERS_BINNACLE: '/private/users/binnacle',
  USERS_CLIENTS: '/users/clients',
  USERS_CREATE: '/users/create',
  USERS_DETAILS: '/private/users_details',
  USERS_GET_INFO: '/private/users/get_info',
  USERS_KEYS: '/private/users/keys',
  USERS_OWNER: '/users/owner',
  USERS_ROLES: '/private/users/roles',
  USERS_ROLES_TEMPLATE: '/private/users/rolestemplate',
  USERS_WEBCLIENTS: '/users/webclients',
};

export const CONF = {
  [SERVICES.PEOPLE_TEMPLATES]: {
    getById: false,
    sort: 'score',
  },
  [SERVICES.DASHBOARD_CHARTS_DATA]: {
    getById: false,
  },
};
