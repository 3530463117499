import { lazy } from 'react';

import settingsAccessRoutes from './access';
import settingsCampaignsRoutes from './campaigns';
import settingsFlowsRoutes from './flows';
import settingsTemplatesRoutes from './templates';

const settingsRoutes = (parentPath) => [
  ...settingsAccessRoutes(`${parentPath}/access`),
  ...settingsCampaignsRoutes(`${parentPath}/campaigns`),
  {
    exact: true,
    path: `${parentPath}/company`,
    component: lazy(() => import('views/Settings/Company')),
  },
  ...settingsFlowsRoutes(`${parentPath}/flows`),
  ...settingsTemplatesRoutes(`${parentPath}/templates`),
];

export default settingsRoutes;
