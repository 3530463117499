import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  rootImage: {
    flexWrap: 'wrap',
    justifyContent: 'start',
    overflow: 'hidden',
  },
  imageList: {
    flexWrap: 'nowrap',
    minWidth: theme.spacing(120),
    margin: '10px !important',
  },
  title: {
    color: theme.palette.secondary.contrastText,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 100%, rgba(0,0,0,0) 100%)',
  },
  button: {
    padding: '2px',
    border: '#00000014',
    borderStyle: 'dashed',
    borderRadius: '5%',
  },
}));
