import { lazy } from 'react';

const settingsAccessRoutes = (parentPath) => [
  {
    exact: true,
    path: `${parentPath}/groups`,
    permission: 'groups.select.listGroups',
    component: lazy(() => import('views/Settings/Access/Groups')),
  },
  {
    exact: true,
    path: `${parentPath}/roles`,
    permission: 'roles.select.listRoles',
    component: lazy(() => import('views/Settings/Access/Roles/List')),
  },
  {
    exact: true,
    path: `${parentPath}/roles/add`,
    component: lazy(() => import('views/Settings/Access/Roles/Edit')),
  },
  {
    exact: true,
    path: `${parentPath}/roles/:rolId`,
    component: lazy(() => import('views/Settings/Access/Roles/Edit')),
  },
  {
    exact: true,
    path: `${parentPath}/users`,
    permission: 'users.select.listUsers',
    component: lazy(() => import('views/Settings/Access/Users/List')),
  },
  {
    exact: true,
    path: `${parentPath}/users/:userId`,
    component: lazy(() => import('views/Settings/Access/Users/Details')),
  },
];

export default settingsAccessRoutes;
