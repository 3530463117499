import { lazy } from 'react';

const peopleRoutes = (parentPath) => [
  {
    exact: true,
    path: `${parentPath}`,
    permission: 'clients.select.listClients',
    component: lazy(() => import('views/Persons/List')),
  },
  {
    exact: true,
    path: `${parentPath}_v2`, // FIXME: Test and move to /people
    permission: 'clients.select.listClients',
    component: lazy(() => import('views/Persons/List.V2')),
  },
  {
    exact: true,
    path: `${parentPath}/add`,
    permission: 'clients.insert.addClients',
    component: lazy(() => import('views/Persons/Add')),
  },
  {
    exact: true,
    path: `${parentPath}/:peopleId`,
    permission: 'clients.select.getClients',
    component: lazy(() => import('views/Persons/Edit')),
  },
  {
    exact: true,
    path: `${parentPath}/:peopleId/pld`,
    component: lazy(() => import('views/Persons/PldModule')),
  },
  {
    exact: true,
    path: `${parentPath}/:peopleId/pld/:queryId`,
    component: lazy(() => import('views/Persons/PldModule')),
  },
  {
    exact: true,
    path: `${parentPath}/:peopleId/products/movements`,
    component: lazy(() => import('views/Persons/Products/Movements/List')),
  },
  {
    exact: true,
    path: `${parentPath}/:peopleId/products/movements/add`,
    component: lazy(() => import('views/Persons/Products/Movements/Add')),
  },
  {
    exact: true,
    path: `${parentPath}/:peopleId/products/:productId`,
    component: lazy(() => import('views/Persons/Products')),
  },
  {
    exact: true,
    path: `${parentPath}/:peopleId/:tabName`,
    component: lazy(() => import('views/Persons/Redirect')),
  },
];

export default peopleRoutes;
