import * as Yup from 'yup';

import MESSAGE from '../../constants/messages';

export const userFormSchema = Yup
  .object()
  .shape({
    attributes: Yup
      .object()
      .shape({
        email: Yup
          .string()
          .email(MESSAGE.EMAIL)
          .lowercase()
          .trim()
          .required(MESSAGE.REQUIRED),
        phone_number: Yup
          .string(),
        name: Yup
          .string()
          .trim(),
        family_name: Yup
          .string()
          .trim(),
      }),
    role: Yup
      .number()
      .required(MESSAGE.REQUIRED),
    group: Yup
      .number()
      .required(MESSAGE.REQUIRED),
  });
