import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Link as RouterLink,
  useLocation,
  matchPath,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
} from '@material-ui/core';
import {
  Add as AddIcon,
  ArrowRight as ArrowRightIcon,
  Book as BookIcon,
  Contacts as ContactsIcon,
  Dashboard as DashboardIcon,
  TableChart as ReportIcon,
  CreditCard as CreditCardIcon,
} from '@material-ui/icons';

import Logo from 'components/Logo';
import { hasPermissions } from 'utils/permissions';

//import NavPldItems from './items/pld';
import NavSettingsItems from './items/settings';
import NavItem from './NavItem';
import useStyles from './styles';

function renderNavItems({ items, ...rest }) {
  const options = items.filter(item => item.permissions
    ? hasPermissions(item.permissions, true)
    : true);

  return (
    <List disablePadding>
      {options.reduce(
        (acc, item) => reduceChildRoutes({
          acc,
          item,
          ...rest,
        }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items.filter(_ => !_.hidden),
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />,
    );
  }

  return acc;
}

function NavBar({ openMobile, openMenu, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { peopleTypes: { items: peopleTypes } } = useSelector((state) => state);

  const checkNavPermissions = function (items) {
    const permissions = items
      .map((item) => Array.isArray(item.permissions)
        ? item.permissions
        : [item.permissions])
      .flat();

    return hasPermissions(permissions, true);
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const navConfig = [
    {
      subheader: '',
      items: [
        {
          href: '/',
          icon: DashboardIcon,
          permissions: 'dashboard.select.listDashboard',
          title: 'Dashboard',
        },
        {
          icon: ReportIcon,
          title: 'Reportes',
          permissions: 'reports.select.listReports',
          href: '/reports',
        },
      ],
    },
    {
      subheader: 'Portafolio',
      items: [
        {
          href: '/people',
          icon: ContactsIcon,
          permissions: 'clients.select.listClients',
          title: 'Clientes',
          items: peopleTypes.length
            ? peopleTypes.map(({ id, name }) => ({
              href: `/people?type=${id}`,
              permissions: 'clients.select.listClients',
              title: name,
            }))
            : [
              {
                href: '/settings/templates/people/add',
                icon: AddIcon,
                permissions: 'clientTypes.insert.addClientTypes',
                title: 'Agregar tipo',
              },
            ],
        },
        {
          href: '/cards',
          icon: CreditCardIcon,
          permissions: 'clients.select.listClients',
          title: 'Tarjetas',
        },
      ],
    },
  ];

  navConfig.push({
    subheader: 'Configuración',
    items: NavSettingsItems,
  });

  if (hasPermissions('logs.select.listBinnacle')) {
    navConfig.push({
      subheader: 'Logs',
      items: [
        {
          href: '/logs/binnacle',
          icon: BookIcon,
          permissions: 'logs.select.listBinnacle',
          title: 'Bitácora',
        },
      ],
    });
  }

  // if (checkNavPermissions(NavPldItems)) {
  //navConfig.push({
  //  subheader: 'PLD',
  //  items: NavPldItems,
  //});
  // }

  if (process.env.NODE_ENV === 'development') {
    navConfig.push({
      subheader: 'Demo views',
      items: [
        {
          title: 'Auth functions',
          icon: ArrowRightIcon,
          href: '/_demo/auth_functions',
        },
        {
          title: 'DynamicForm',
          icon: ArrowRightIcon,
          href: '/_demo/forms',
        },
        {
          title: 'New Relic',
          icon: ArrowRightIcon,
          href: '/_demo/new_relic',
        },
      ],
    });
  }

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{
        suppressScrollX: true,
      }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo
                style={{
                  maxHeight: 48,
                }}
                variant="imagotipo-horizontal"
              />
            </RouterLink>
          </Box>
        </Hidden>

        <Divider />
        <Box p={2}>
          {navConfig.map((config, index) => (
            <List
              key={index}
              subheader={
                <ListSubheader
                  children={config.subheader}
                  disableGutters
                  disableSticky
                />
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          children={content}
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        />
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          children={content}
          classes={{
            paper: classes[openMenu
              ? 'desktopDrawer'
              : 'desktopMiniDrawer'],
          }}
          open={openMenu}
          variant="persistent"
        />
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  openMenu: PropTypes.bool,
};

export default NavBar;
