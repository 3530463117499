import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Paper,
  SvgIcon,
  TextField,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

import { DynamicForm } from 'components';

import useStyles from './styles';

function Filters(props) {
  const classes = useStyles();
  const {
    filters,
    searchAction,
    handleQueryChange,
    query,
    isTypeCard,
  } = props;

  const isVisible = !!searchAction || filters?.fields?.length > 0;

  if (!isVisible) {
    return null;
  }

  return (<Paper className={classes.filterCard}>
    <Card>
      <CardContent>
        {filters?.fields?.length > 0 && (
          <DynamicForm
            initialValues={filters.values}
            fields={filters.fields}
          />
        )}

        {!!searchAction && (<Grid container spacing={1}>
          <Grid item md={2} xs={6} />
          <Grid item md={2} xs={6} />
          <Grid item md={2} xs={6} />
          <Grid item md={2} xs={6} />
          <Grid item md={4} xs={12}>
            {isTypeCard ? (
              <TextField
                fullWidth
                inputProps={{
                  maxLength: 16,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        children={<SearchIcon />}
                        color="action"
                        fontSize="small"
                      />
                    </InputAdornment>
                  ),
                }}
                label="Buscar tarjeta"
                name="query"
                //onChange={handleQueryChange}
                onInput={(e) => {
                  e.target.value = e.target.value.slice(0, 16);
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  handleQueryChange(e);
                }}
                placeholder="Buscar tarjeta..."
                size="small"
                variant="outlined"
                value={query}
              />
            ) : (
              <TextField
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        children={<SearchIcon />}
                        color="action"
                        fontSize="small"
                      />
                    </InputAdornment>
                  ),
                }}
                label="Buscar"
                name="query"
                onChange={handleQueryChange}
                placeholder="Buscar..."
                size="small"
                variant="outlined"
                value={query}
              />
            )}
          </Grid>
        </Grid>)}

      </CardContent>
    </Card>
  </Paper>);
}

Filters.propTypes = {
  filters: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
    values: PropTypes.array,
  }),
  handleQueryChange: PropTypes.func,
  query: PropTypes.string,
  searchAction: PropTypes.func,
  isTypeCard: PropTypes.bool,
};

export default Filters;
