import * as Yup from 'yup';

import MESSAGE from 'validations/constants/messages';

export const templatesFilesFormSchema = Yup
  .object()
  .shape({
    name: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    channel: Yup
      .number()
      .typeError('Selecciona una opción')
      .required(MESSAGE.REQUIRED),
    subject: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    html: Yup
      .string(),
  });
