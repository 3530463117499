import { lazy } from 'react';

const productsRoutes = (parentPath) => [
  {
    exact: true,
    path: `${parentPath}`,
    component: lazy(() => import('views/Products/List')),
  },
  {
    exact: true,
    path: `${parentPath}/:productId`,
    component: lazy(() => import('views/Products/Details')),
  },
];

export default productsRoutes;
