import { lazy } from 'react';

import PublicFormsLayout from 'layouts/PublicFormsLayout';

const error404 = {
  component: lazy(() => import('views/Errors/Error404')),
};

const publicRoutes = {
  path: '/public',
  layout: PublicFormsLayout,
  routes: [
    {
      exact: true,
      path: '/public/people/form/:type',
      component: lazy(() => import('views/Public/Persons/Form')),
    },
    {
      exact: true,
      path: '/public/people/sat/keys/:token',
      component: lazy(() => import('views/Public/Persons/Sat/AccessKeys')),
    },
    {
      exact: true,
      path: '/public/integrations/enpagos',
      component: lazy(() => import('views/Public/Integrations/Enpagos')),
    },
    error404,
  ],
};

export default publicRoutes;
