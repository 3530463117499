import { lazy } from 'react';

const settingsCampaignsRoutes = (parentPath) => [
  {
    exact: true,
    path: `${parentPath}`,
    permission: 'campaigns.select.listCampaigns',
    component: lazy(() => import('views/Settings/Campaigns/List')),
  },
  {
    exact: true,
    path: `${parentPath}/add`,
    permission: 'campaigns.insert.addCampaigns',
    component: lazy(() => import('views/Settings/Campaigns/Edit')),
  },
  {
    exact: true,
    path: `${parentPath}/:campaignId`,
    permission: 'campaigns.select.getCampaigns',
    component: lazy(() => import('views/Settings/Campaigns/Edit')),
  },
  {
    exact: true,
    path: `${parentPath}/:campaignId/historical`,
    component: lazy(() => import('views/Settings/Campaigns/Historical')),
  },
  {
    exact: true,
    path: `${parentPath}/:campaignId/historical/:historicalId`,
    component: lazy(() => import('views/Settings/Campaigns/Historical/Details')),
  },
];

export default settingsCampaignsRoutes;
