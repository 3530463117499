import * as Yup from 'yup';

import MESSAGE from '../../constants/messages';
import { getLocalDate } from '../../functions/date';

export const pldInternalReportsSchema = Yup
  .object()
  .shape({
    id: Yup
      .number()
      .integer()
      .positive(),
    name: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    lastname: Yup
      .string()
      .default(' '),
    dob: Yup
      .date()
      .default(() => getLocalDate()),
    taxid: Yup
      .string()
      .uppercase()
      .trim()
      .max(13, MESSAGE.MAX_LENGTH)
      .min(12, MESSAGE.MIN_LENGTH)
      .required(MESSAGE.REQUIRED),
    uid: Yup
      .string()
      .uppercase()
      .trim()
      .length(18)
      .required(MESSAGE.REQUIRED),
    reason: Yup
      .array()
      .of(Yup.number())
      .default([]),
    type: Yup
      .string()
      .oneOf([
        'Unusual',
        'Worrying',
      ])
      .required(),
    note: Yup
      .string()
      .trim()
      .required(),
  });
