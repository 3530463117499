import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { w3cwebsocket } from 'websocket';

import {
  getTableData,
  setQueryLoading,
} from 'redux/actions/peopleSat';
import { updateWebSocket } from 'redux/actions/system';
import { WEBSOCKET_URL } from 'settings';
import { getCurrentSessionData } from 'utils/aws/cognito';

function WssProvider() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { accessToken } = getCurrentSessionData();
  const { t } = useTranslation();
  const wssClient = new w3cwebsocket(`${WEBSOCKET_URL}/user?token=${accessToken}`);

  useEffect(() => {
    wssClient.onopen = () => {
      console.debug('WebSocket client: Connected');
    };

    wssClient.onmessage = ({ data }) => {
      const message = JSON.parse(data);

      dispatch(updateWebSocket(message));

      switch (message.type) {
        default: break;

        case 'people_sat_query':
          const client = Number(location.pathname.replace('/people/', ''));
          if (location.hash === '#sat' && client) {
            dispatch(getTableData({
              client,
            }));
          }

          if (message?.name === 'api.sat.finish') {
            dispatch(setQueryLoading(false));
          }
          break;
      }

      enqueueSnackbar(t(message.name), {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 5000,
        variant: message.level || 'default',
      });
    };

    wssClient.onclose = () => {
      console.debug('WebSocket client: Disconnected');
    };

    return () => wssClient.close();
  }, []);

  return (<></>);
}

export default WssProvider;
