import {
  SET_TABLE_DATA,
  HAS_ERROR,
  IS_LOADING,
  READ_ALL,
  READ_PENDINGS,
  SET_LAST_UPDATE,
} from 'redux/constants/notifications';
import { initialState } from 'redux/reducers/notifications';
import Dynamicore, { SERVICES } from 'services/dynamicore';
import { pickValue } from 'utils/objects';

const { NOTIFICATIONS_ALERTS: SERVICE } = SERVICES;

const HEADERS = [
  {
    name: 'created_at',
    label: 'Fecha',
  },
  {
    name: 'message.name',
    label: 'Nombre',
  },
  {
    name: 'message.type',
    label: 'Tipo',
  },
  {
    name: 'message.alert_type',
    label: 'Alerta',
  },
  {
    name: 'readed',
    label: 'Leida',
  },
];

const getData = async (filters = {}) => {
  const {
    total = 0,
    values: items = [],
  } = await Dynamicore(SERVICE).get(filters);

  return {
    items,
    total,
  };
};

const setError = (error = null) => ({
  type: HAS_ERROR,
  payload: {
    error,
  },
});

const setLastUpdate = () => ({
  type: SET_LAST_UPDATE,
  payload: {
    lastUpdate: new Date(),
  },
});

const setLoading = (isLoading = false) => ({
  type: IS_LOADING,
  payload: {
    isLoading,
  },
});

const setPayload = (type, payload) => ({
  type,
  payload,
});

const setTable = (table = initialState.table) => ({
  type: SET_TABLE_DATA,
  payload: {
    table,
  },
});

function getTableData(query = {}) {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));

    const { table } = getState().notifications;
    const {
      limit = table.pagination.perPageOptions[0],
      page = 1,
    } = query;

    try {
      const { items, total } = await getData({
        ...query,
        limit,
        page,
      });

      const rows = items.map((item) => {
        item.readed = item.readed === '1';

        return ({
          id: item.id,
          data: item,
          values: HEADERS.map(({ name }) => pickValue(item, name)),
        });
      });

      dispatch(setTable({
        ...table,
        headers: HEADERS.map(({ label }) => label),
        rows,
        total,
      }));
      dispatch(setError());
      dispatch(setLastUpdate());
    } catch ({ code = SET_TABLE_DATA, message }) {
      const error = new Error();
      error.code = code;
      error.message = message;

      dispatch(setError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
}

function readAll() {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const { items: rows, total } = await getData();

      dispatch(setPayload(READ_ALL, {
        items: {
          rows,
          total,
        },
      }));
      dispatch(setError());
      dispatch(setLastUpdate());
    } catch ({ code = READ_ALL, message }) {
      const error = new Error();
      error.code = code;
      error.message = message;

      dispatch(setError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
}

function readPendings() {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const { items: rows, total } = await getData({
        readed: 0,
      });

      dispatch(setPayload(READ_PENDINGS, {
        pendings: {
          rows,
          total,
        },
      }));
      dispatch(setError());
      dispatch(setLastUpdate());
    } catch ({ code = READ_PENDINGS, message }) {
      const error = new Error();
      error.code = code;
      error.message = message;

      dispatch(setError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export {
  getTableData,
  readAll,
  readPendings,
};
