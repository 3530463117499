import { createElement } from 'react';

import { Label } from 'components';
import {
  HAS_ERROR,
  IS_LOADING,
  READ_ALL,
  SET_LAST_UPDATE,
  SET_QUERY_LOADING,
  SET_TABLE_DATA,
} from 'redux/constants/peopleSat';
import Dynamicore, { SERVICES } from 'services/dynamicore';
import { paginate } from 'utils/arrays';
import { dateTimeFormat, numberFormat } from 'utils/formater';
import { pickValue } from 'utils/objects';

const { SAT: SERVICE } = SERVICES;
const HEADERS = [
  {
    name: 'uuid',
    label: 'UUID',
  },
  {
    name: 'rfcemisor',
    label: 'RFC Emisor',
  },
  {
    name: 'nombreemisor',
    label: 'Nombre del emisor',
  },
  {
    name: 'rfcreceptor',
    label: 'RFC Receptor',
  },
  {
    name: 'nombrereceptor',
    label: 'Nombre del receptor',
  },
  {
    name: 'monto',
    label: 'Monto',
    type: 'number',
    format: 'currency',
  },
  {
    name: 'fechaemision',
    label: 'Fecha de emisión',
    type: 'datetime',
  },
  {
    name: 'fechacertificacionsat',
    label: 'Fecha de certificación',
    type: 'datetime',
  },
  {
    name: 'estatus',
    label: 'Estatus',
    type: 'component',
    variant: 'label',
  },
  {
    name: 'fechacancelacion',
    label: 'Fecha de cancelación',
    type: 'datetime',
  },
];

const getData = async (filters = {}) => {
  const {
    total = 0,
    values: items = [],
  } = await Dynamicore(SERVICE).get(filters);

  return {
    items,
    total,
  };
};

const setError = (error = null) => ({
  type: HAS_ERROR,
  payload: {
    error,
  },
});

const setLastUpdate = () => ({
  type: SET_LAST_UPDATE,
  payload: {
    lastUpdate: new Date(),
  },
});

const setLoading = (isLoading = false) => ({
  type: IS_LOADING,
  payload: {
    isLoading,
  },
});

const setPayload = (type, payload) => ({
  type,
  payload,
});

export function getTableData(query = {}, pagination = {}) {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));

    const { items, table } = getState().peopleSat;
    const {
      limit = table.pagination.perPageOptions[0],
      page = 1,
    } = pagination;

    try {
      let allRows = items, total = items.length;

      if (page === 1 && !query._search) {
        const data = await getData({
          ...query,
        });

        allRows = data.items || [];
        total = data.total || 0;

        dispatch(setPayload(READ_ALL, {
          items: allRows,
        }));
        dispatch(setLastUpdate());
      }

      if (query._search) {
        const search = query._search.toLowerCase();

        allRows = allRows
          .filter(i =>
            Object.values(i)
              .map(
                j => String(j)
                  .toLowerCase()
                  .includes(search))
              .includes(true));
      }

      const rows = paginate(allRows, limit, page)
        .map((item) => {
          return ({
            id: item.uuid,
            data: item,
            values: HEADERS.map(({ format, name, type, variant }) => {
              let align = 'left';
              let value;

              switch (type) {
                default: {
                  value = pickValue(item, name);
                  break;
                }

                case 'component': {
                  const children = pickValue(item, name);

                  switch (variant) {
                    default: {
                      value = pickValue(item, name);
                      break;
                    }

                    case 'label': {
                      align = 'center';
                      value = createElement(Label, {
                        children: children === 1 ? 'Activa' : 'Cancelada',
                        color: children === 1 ? 'success' : 'error',
                      });
                      break;
                    }
                  }

                  break;
                }

                case 'datetime': {
                  align = 'center';
                  value = dateTimeFormat(pickValue(item, name));
                  break;
                }

                case 'number': {
                  let prefix;
                  align = 'right';

                  switch (format) {
                    default: { break; }

                    case 'currency': {
                      prefix = '$';
                      break;
                    }
                  }

                  value = numberFormat(pickValue(item, name), prefix);
                  break;
                }
              }

              return {
                align,
                label: value,
              };
            }),
          });
        });

      dispatch(setPayload(SET_TABLE_DATA, {
        table: {
          ...table,
          headers: HEADERS,
          rows,
          total,
        },
      }));
      dispatch(setError());
    } catch ({ code = SET_TABLE_DATA, message }) {
      const error = new Error();
      error.code = code;
      error.message = message;

      dispatch(setError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function readAll() {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const { items } = await getData();

      dispatch(setPayload(READ_ALL, {
        items,
      }));
      dispatch(setLastUpdate());
      dispatch(setError());
    } catch ({ code = READ_ALL, message }) {
      const error = new Error();
      error.code = code;
      error.message = message;

      dispatch(setError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function search() {
  return async (dispatch, getState) => {

  };
}

export function setQueryLoading(queryIsLoading = false) {
  return async (dispatch) => {
    dispatch({
      type: SET_QUERY_LOADING,
      payload: {
        queryIsLoading,
      },
    });
  };
};
