import {
  GroupWork as GroupIcon,
  LockOpen as LockOpenIcon,
  PeopleOutline as PeopleOutlineIcon,
} from '@material-ui/icons';

const items = [
  {
    href: '/settings/access/groups',
    icon: GroupIcon,
    permissions: 'groups.select.listGroups',
    title: 'Grupos',
  },
  {
    href: '/settings/access/roles',
    icon: LockOpenIcon,
    permissions: 'roles.select.listRoles',
    title: 'Roles',
  },
  {
    href: '/settings/access/users',
    icon: PeopleOutlineIcon,
    permissions: 'users.select.listUsers',
    title: 'Usuarios',
  },
];

export default items;
