import { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment/locale/es';

import Auth from 'components/Auth';
import ScrollReset from 'components/ScrollReset';
import WssProvider from 'components/WssProvider';
import useSettings from 'hooks/useSettings';
import { peopleTypesRequest } from 'redux/actions/peopleTypes';
import { readPendings } from 'redux/actions/notifications';
import Routes from 'routes';
import { createTheme } from 'theme';
import { getCurrentSessionData } from 'utils/aws/cognito';

const history = createBrowserHistory();
const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});
const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    },
  }),
);


function App() {
  const dispatch = useDispatch();
  const { data: { company: publicFormCompany } = {} } = useSelector(({ publicForms }) => publicForms);

  const loadData = async () => {
    const { decodeAccessToken } = getCurrentSessionData();

    if (Date.now() < decodeAccessToken?.exp * 1000) {
      dispatch(peopleTypesRequest({
        limit: 25,
        page: 1,
      }));

      dispatch(readPendings());
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  useStyles();

  const { settings } = useSettings();

  return (
    <ThemeProvider theme={createTheme({
      ...settings,
      ...(publicFormCompany?.theme || {}),
    })}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
          <SnackbarProvider maxSnack={1}>
            <Router history={history}>
              <Auth>
                <WssProvider />
                <ScrollReset />
                <Routes />
              </Auth>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
