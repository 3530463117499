import AwsS3 from 'aws-sdk/clients/s3';

import {
  AWS_BUCKET as BUCKET,
  AWS_CREDENTIALS as CREDENTIALS,
  AWS_REGION as REGION,
} from 'settings/aws';

const client = new AwsS3({
  apiVersion: '2006-03-01',
  region: REGION,
  credentials: CREDENTIALS,
});

export async function checkKeyExist(path, repository = BUCKET) {
  const key = path.replace(`https://${repository}.s3.${REGION}.amazonaws.com/`, '');
  const params = {
    Bucket: repository,
    Key: key,
  };

  try {
    await client
      .headObject(params)
      .promise();

    return true;
  } catch (e) {
    return false;
  }
}

/**
* Download file from AWS S3
*
* @param {string} path
*/
export async function download(path) {
  try {
    const url = await getSignedUrl(path);
    window.open(url);
  } catch (error) {
    return false;
  }

  return true;
}

/**
 * Get signed URL
 *
 * @param {string} path
 */
export async function getSignedUrl(path, expires = 10, repository = BUCKET) {
  const key = path.replace(`https://${repository}.s3.${REGION}.amazonaws.com/`, '');
  const params = {
    Bucket: repository,
    Key: key,
    Expires: expires,
  };

  return client.getSignedUrl('getObject', params);
}

/**
* Upload file to AWS S3
*
* @param {string} path
* @param {(Buffer|Uint8Array|Blob|string|Readable)} file
*/
export async function upload(path, file) {
  const params = {
    Body: file,
    Bucket: BUCKET,
    Key: path,
    ContentType: file.type,
  };
  const options = {
    partSize: 50 * 1024 * 1024,
    queueSize: 1,
  };

  return client.upload(params, options).promise();
}
