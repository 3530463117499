import { createElement } from 'react';
import moment from 'moment';

import { Link } from 'components';
import { dateFormat, dateTimeFormat } from 'utils/formater';
import { convertToObject } from 'utils/arrays';
import Excel from 'utils/xlsx';

function filterListRender(value, { options }) {
  let label;

  if (Array.isArray(options)) {
    label = convertToObject(options)[value]?.name;
  }

  return label || value;
}

function downloadExcel(columns, rows, title) {
  const tableTitle = (title && typeof title === 'string')
    ? title
    : 'File';
  const filename = `${tableTitle} ${moment().format('YYYYMMDDHHmmss')}`;
  const hasActions = !!columns.find((column) => column.name === '__actions');

  new Excel(
    [
      {
        autofilter: true,
        name: tableTitle,
        data: [
          columns
            .filter((column) => !column.name.startsWith('__'))
            .map((column) => column.label),
          ...rows.map(row => {
            const aod = [...row.data];

            if (hasActions) {
              aod.pop();
            }

            return aod;
          }),
        ],
      },
    ],
    {
      title: title,
    },
  ).download(filename, 'xlsx');
}

function formatCell(item, value) {
  let textAlign = [
    'label',
  ].includes(item?.variant?.mapping) ? 'center' : 'left';
  let cellValue = filterListRender(value, item);

  switch (item.type) {
    default: {
      switch (item.format) {
        default: {
          textAlign = item.align;

          break;
        }

        case 'email': {
          cellValue = createElement(Link, {
            children: cellValue,
            to: cellValue,
            variant: 'email',
          });
          break;
        }

        case 'url': {
          cellValue = createElement(Link, {
            children: cellValue,
            to: cellValue,
            variant: 'external',
          });
          break;
        }
      }

      break;
    }

    case 'date': {
      textAlign = 'center';
      cellValue = dateFormat(cellValue);
      break;
    }

    case 'datetime': {
      textAlign = 'center';
      cellValue = dateTimeFormat(cellValue, undefined, true);
      break;
    }

    case 'number': {
      textAlign = 'right';
      let minimumFractionDigits = 2;
      let maximumFractionDigits = 2;
      let prefix = '';

      switch (item.format) {
        default: { break; }

        case 'currency': {
          prefix = '$';
          break;
        }

        case 'decimal': {
          break;
        }

        case 'integer': {
          minimumFractionDigits = undefined;
          maximumFractionDigits = undefined;
          break;
        }
      }

      cellValue = prefix + new Intl.NumberFormat('es-MX', {
        minimumFractionDigits,
        maximumFractionDigits,
      }).format(cellValue || 0);
      break;
    }
  }

  return {
    textAlign,
    cellValue,
  };
}

function getData({ rows = [], actions = [] }) {
  return rows.map((item) => ({
    ...item,
    __actions: actions.length
      ? actions
      : undefined,
  }));
}

export {
  downloadExcel,
  filterListRender,
  formatCell,
  getData,
};
