import {
  CardGiftcard as CardGiftcardIcon,
  Description as DescriptionIcon,
  SupervisedUserCircleOutlined as UserIcon,
} from '@material-ui/icons';

const items = [
  {
    title: 'Clientes',
    href: '/settings/templates/people',
    permissions: 'clientTypes.select.listClientTypes',
    icon: UserIcon,
  },
  {
    title: 'Notificaciones',
    href: '/settings/templates/notifications',
    permissions: 'notificationTypes.select.listNotificationTypes',
    icon: DescriptionIcon,
  },
  {
    title: 'Productos',
    href: '/settings/templates/products',
    permissions: 'productTypes.select.listProductTypes',
    icon: CardGiftcardIcon,
  },
];

export default items;
