import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Fullscreen as FullscreenIcon,
} from '@material-ui/icons';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {},
  firstButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  secondButton: {
    position: 'absolute',
    right: theme.spacing(6),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function Modal(props) {
  const {
    actions,
    canFullScreen,
    children,
    events,
    fullWidth,
    maxWidth,
    open,
    title,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [forceFull, setForceFull] = useState(false);

  const { onClose } = events;

  return (
    <Dialog
      disableBackdropClick
      fullWidth={fullWidth}
      fullScreen={fullScreen || forceFull}
      maxWidth={maxWidth}
      onClose={onClose}
      open={open}
      aria-labelledby="simple-dialog-title"
    >
      {title && <DialogTitle id="simple-dialog-title">
        {title}
      </DialogTitle>}

      {(canFullScreen && !fullScreen) && (<IconButton
        aria-label="fullscreen"
        className={onClose ? classes.secondButton : classes.firstButton}
        onClick={() => setForceFull(!forceFull)}
      >
        <FullscreenIcon />
      </IconButton>)}

      {onClose &&
        <IconButton
          aria-label="close"
          className={classes.firstButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      }

      {children && (
        <DialogContent dividers>
          {children}
        </DialogContent>
      )}

      {!!actions.length && <>
        <DialogActions>
          {actions.map((item, index) => (
            <Button
              children={item.label}
              color={item.processing ? 'secondary' : item.color}
              disabled={item.processing || item.disabled}
              form={item.form}
              key={index}
              startIcon={item.processing ?
                (<CircularProgress
                  color="secondary"
                  size={18}
                  style={{
                    marginRight: 5,
                  }}
                />)
                : item.icon}
              style={{
                ...(item.hidden ? {
                  display: 'none',
                } : {}),
                ...(item.left ? {
                  marginRight: 'auto',
                } : {}),
                ...(item.color === 'error' && !item.processing ? {
                  color: 'white',
                  backgroundColor: red[500],
                  '&:hover': {
                    backgroundColor: red[900],
                  },
                } : {}),
              }}
              type={item.type}
              variant={item.color ? 'contained' : 'text'}
              onClick={item.fn || item.action}
            />
          ))}
        </DialogActions>
      </>}

    </Dialog >
  );
}

Modal.defaultProps = {
  actions: {},
  events: {},
  fullWidth: true,
  maxWidth: 'sm',
  open: false,
};

Modal.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.oneOf([
      'error',
      'primary',
      'secondary',
    ]),
    disabled: PropTypes.bool,
    fn: PropTypes.func,
    icon: PropTypes.element,
    label: PropTypes.string,
    processing: PropTypes.bool,
  })),
  canFullScreen: PropTypes.bool,
  children: PropTypes.element,
  events: PropTypes.shape({
    onClose: PropTypes.func,
  }),
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.oneOf([
    'lg',
    'md',
    'sm',
    'xl',
    'xs',
  ]),
  open: PropTypes.bool,
  title: PropTypes.string,
};

export default Modal;
