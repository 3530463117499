import { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
} from '@material-ui/core';

function LoaderCardContainer({ children }) {
  return (<Box mt={2}>
    <Card>
      <CardContent
        children={children}
      />
    </Card>
  </Box>);
}

LoaderCardContainer.propTypes = {
  children: PropTypes.node,
};

function Loader({ color, size, variant }) {
  const Component = variant === 'card'
    ? LoaderCardContainer
    : Fragment;

  return (<Component>
    <Grid container justifyContent="center">
      <CircularProgress
        color={color}
        size={size}
        style={{
          marginTop: 10,
        }}
      />
    </Grid>
  </Component>);
}

Loader.defaultProps = {
  color: 'secondary',
  size: 30,
};

Loader.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
  ]),
  size: PropTypes.number,
  variant: PropTypes.oneOf(['card']),
};

export default Loader;
