/* eslint-disable no-template-curly-in-string */
const MESSAGES = {
  EMAIL: 'Escribe una dirección de correo electrónico válida',
  MAX_LENGTH: 'Debe contener máximo ${max} caracteres',
  MIN_LENGTH: 'Debe contener mínimo ${min} caracteres',
  PASSWORD: 'La contraseña debe contener al menos 8 caracteres, mayúsculas y minúsculas',
  REQUIRED: 'Campo requerido',
  URL: 'Escribe una URL válida',
  USERNAME_PHONE_EMAIL: 'Escriba un teléfono o correo electrónico válido',
  TYPE_ERROR_NUMBER: 'Selecciona un elemento válido',
};

export default MESSAGES;
