import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { numberFormat } from 'utils/formater';
import lockImg from 'assets/images/Lock.png';
import mastercardImg from 'assets/images/mastercardLogo.png';

const useStyles = makeStyles((theme) => ({
  text: {
    color: '#213134',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '18px',
    textDecoration: 'none !important',
  },
  cardContainer: {
    width: '300px',
    height: '200.78px',
    background: 'linear-gradient(90deg, #193754 0%, #5A618A 100%)', 
    //backgroundColor: 'rgb(30,55,88)',
    backdropFilter: 'blur(47.9147px)',
    borderRadius: '17.5877px',
    boxShadow: '0px 5px 5px 0px rgba(0, 0, 0, 0.2)',
  },
  card: {
    padding: '20px 20px',
  },
  textName: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '10px',
    color: '#FFFFFF',
    opacity: '0.9',
  },
  textAmount: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '10px',
    color: '#FFFFFF',
  },
  amount: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#FFFFFF',
  },
  lastFour: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#FFFFFF',
    letterSpacing: '0.34em',
  },
  personName: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#FFFFFF',
  },
  lockImg: {
    display: 'flex',
    justifyContent: 'flex-end !important',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-end !important',
    },
  },
  dataCard: {
    marginTop: '50px',
  },
  spaceName: {
    marginTop: '35px',
  },
  mastercardImg: {
    display: 'flex',
    justifyContent: 'flex-end !important',
    paddingTop: '15px',
  },
}));

function CardComponent({ amount, last_four, name, isBlocked }) {

  const classes = useStyles();

  return (
    <Grid container className={classes.cardContainer}>
      <Grid item className={classes.card}>
        <Grid container>
          <Grid item lg={6} md={6}>
            <Typography 
              variant="h6"
              className={classes.textAmount}>Monto de tarjeta: 
            </Typography>
            <Typography
              variant="h6"
              className={classes.amount}>{numberFormat(amount, '$ ')}
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} className={classes.lockImg}>
            {isBlocked && <img src={lockImg} alt="lock" />} {/** Cambiar el isBlocked a true */}
          </Grid>
          <Grid container className={classes.dataCard}>
            <Grid item lg={12}>
              <Typography
                variant="h6"
                className={classes.lastFour}>**** **** **** {last_four}
              </Typography>
            </Grid>
            <Grid item lg={6} className={classes.spaceName}>
              {/*
              <Typography
                variant="h6"
                className={classes.textName}>Nombre
              </Typography>
              <Typography
                variant="h6"
                className={classes.personName}>{name}
              </Typography>
              */}
            </Grid>
            <Grid item lg={6} className={classes.mastercardImg}>
              <img src={mastercardImg} alt="mastercard" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

CardComponent.propTypes = {
  amount: PropTypes.number,
  last_four: PropTypes.string,
  name: PropTypes.string,
  isBlocked: PropTypes.bool,
};

CardComponent.defaultProps = {
  amount: 0,
  last_four: '0000',
  name: 'Metricas',
  isBlocked: false,
};

export default CardComponent;
