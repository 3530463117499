import AWS from 'aws-sdk';
import jwtDecode from 'jwt-decode';

import {
  AWS_CREDENTIALS,
  AWS_REGION as region,
  AWS_USER_POOLS_ID as UserPoolId,
  AWS_USER_POOLS_WEB_CLIENT_ID,
} from 'settings/aws';
import { parseIfValid } from 'utils/json';

AWS.config.update({
  region,
  ...AWS_CREDENTIALS,
});

const client = new AWS.CognitoIdentityServiceProvider({
  apiVersion: '2016-04-18',
});

function processUserData(user) {
  const attributes = user.UserAttributes || user.Attributes || [];

  return {
    createDate: user.UserCreateDate,
    enabled: user.Enabled,
    lastModifiedDate: user.UserLastModifiedDate,
    preferredMfa: user.PreferredMfaSetting,
    status: user.UserStatus,
    username: user.Username,
    attributes: Object
      .fromEntries(attributes.map(({ Name, Value }) => ([Name, Value]))),
  };
};

async function adminCreateUser(username, resend = false) {
  const messageAction = resend ? 'RESEND' : '';

  await client.adminCreateUser({
    Username: username,
    UserPoolId,
    MessageAction: messageAction,
  }).promise();
}

async function adminEnabledUser(username, enabled) {

  return client[`admin${enabled ? 'Enable' : 'Disable'}User`]({
    Username: username,
    UserPoolId,
  }).promise();
}

async function getUser(username, ignoreError = false) {
  try {
    const user = await client.adminGetUser({
      UserPoolId,
      Username: username,
    }).promise();

    return processUserData(user);
  } catch (e) {
    if (ignoreError) {
      return null;
    } else {
      throw e;
    }
  }
}

function getCurrentSessionData() {
  const payload = {};
  let storageKey = `CognitoIdentityServiceProvider.${AWS_USER_POOLS_WEB_CLIENT_ID}`;

  storageKey += `.${localStorage.getItem(`${storageKey}.LastAuthUser`)}`;

  [
    'accessToken',
    'idToken',
    'refreshToken',
    'userData',
  ].forEach(key => {
    payload[key] = parseIfValid(localStorage.getItem(`${storageKey}.${key}`));
  });

  if (payload?.userData?.UserAttributes) {
    payload.userData.UserAttributes = Object
      .fromEntries((payload.userData?.UserAttributes || [])
        .map(({ Name, Value }) => [
          Name,
          Value,
        ]));
  }

  try {
    payload.decodeAccessToken = jwtDecode(payload.accessToken);
  } catch (e) {
    payload.decodeAccessToken = null;
  }

  return payload;
}

async function listUsers() {
  const { Users: users } = await client.listUsers({
    UserPoolId,
  }).promise();

  return users.map(processUserData);
}

async function updateUserAttributes(username, attributes = {}) {
  const payload = {
    ...attributes,
    sub: undefined,
  };

  const userAttributes = Object
    .entries(payload)
    .map(([Name, Value]) => ({
      Name,
      Value,
    })).filter(_ => _.Value);

  return client.adminUpdateUserAttributes({
    UserAttributes: userAttributes,
    UserPoolId,
    Username: username,
  }).promise();
}

export {
  adminCreateUser,
  adminEnabledUser,
  getCurrentSessionData,
  getUser,
  listUsers,
  updateUserAttributes,
};
