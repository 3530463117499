import { lazy } from 'react';

const logsRoutes = (parentPath) => [
  {
    exact: true,
    path: `${parentPath}/binnacle`,
    permission: 'logs.select.listBinnacle',
    component: lazy(() => import('views/Logs/Binnacle/List')),
  },
];

export default logsRoutes;
