import * as Yup from 'yup';

import MESSAGE from 'validations/constants/messages';

export const companyFormSchema = Yup
  .object()
  .shape({
    id: Yup
      .number()
      .integer()
      .positive(),
    name: Yup
      .string()
      .trim()
      .nullable()
      .required(MESSAGE.REQUIRED),
    street_address: Yup
      .string()
      .trim()
      .nullable(),
    city: Yup
      .string()
      .trim()
      .nullable(),
    zipcode: Yup
      .string()
      .trim()
      .nullable(),
    state: Yup
      .string()
      .trim()
      .nullable(),
    country: Yup
      .string()
      .trim()
      .nullable(),
    email: Yup
      .string()
      .email(MESSAGE.EMAIL)
      .required(MESSAGE.REQUIRED),
    public_key: Yup
      .string()
      .nullable(),
    logo: Yup
      .object()
      .shape({
        imagotipoDarkH: Yup.string().url(MESSAGE.URL),
        imagotipoHorizontal: Yup.string().url(MESSAGE.URL),
      }),
    theme: Yup
      .object()
      .shape({
        primary: Yup.string(),
        secondary: Yup.string(),
      }),
    site: Yup
      .object()
      .shape({
        title: Yup.string().trim(),
        favicon: Yup.string().url(MESSAGE.URL),
        terms_sheet: Yup.string().url(MESSAGE.URL),
        privacy_notice: Yup.string().url(MESSAGE.URL),
      }),
  });
