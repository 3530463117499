import pkg from '../../package.json';

export const {
  name: APP_NAME = 'react-app',
  version: APP_VERSION = '0.0.0',
} = pkg;

export const {
  REACT_APP_API_URL: API_URL,
  REACT_APP_API_CONNECTOR_URL: API_CONNECTOR_URL,
  REACT_APP_API_CONTEXT: API_CONTEXT,

  REACT_APP_BUILD_VERSION: BUILD_VERSION = 'DEV',

  REACT_APP_FORMATS_DATE: FORMAT_DATE = 'YYYY-MM-DD',
  REACT_APP_FORMATS_DATETIME: FORMAT_DATETIME = 'YYYY-MM-DD HH:mm:ss',
  REACT_APP_FORMATS_NUMBER: FORMAT_NUMBER = '0,0.00',
  REACT_APP_FORMATS_TIME: FORMAT_TIME = 'HH:mm:ss',

  REACT_APP_SITE_TITLE: SITE_TITLE,

  REACT_APP_USERS_MODE: USERS_MODE,
  REACT_APP_USERS_PERMISSIONS: USERS_PERMISSIONS,

  REACT_APP_WEBSOCKET_URL: WEBSOCKET_URL,

  TABLES_ITEMS_PER_PAGE = [25, 50, 100, 250],
} = process.env;

export const ENABLE_REDUX_LOGGER = false;
