import { lazy } from 'react';

const notificationsRoutes = (parentPath) => [
  {
    exact: true,
    path: `${parentPath}`,
    permission: 'notifications.select.listNotifications',
    component: lazy(() => import('views/Notifications/List')),
  },
  {
    exact: true,
    path: `${parentPath}/:type`,
    component: lazy(() => import('views/Notifications/Redirect')),
  },
  {
    exact: true,
    path: `${parentPath}/:type/:historicalId`,
    permission: 'notifications.select.listNotificationsResult',
    component: lazy(() => import('views/Settings/Campaigns/Historical/Details')),
  },
];

export default notificationsRoutes;
