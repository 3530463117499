import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  Hidden,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Business as BusinessIcon,
  ExitToApp as ExitToAppIcon,
  VpnLock as VpnLockIcon,
} from '@material-ui/icons';
import { useDispatch } from 'react-redux';

import { logOutCompletely } from 'redux/actions/auth';
import { hasPermissions } from 'utils/permissions';

import AccountModal from './AccountModal';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  avatarArrow: {
    paddingLeft: '0.15em',
  },
  popover: {
    width: 220,
  },
}));

function AccountMenuControl() {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [modal, setModal] = useState();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async function () {
    try {
      await dispatch(logOutCompletely());

      window.location.replace('/auth/login');
    } catch (error) {
      enqueueSnackbar(process.env.NODE_ENV === 'development' ? error.message : 'Error al salir', {
        variant: 'error',
      });
    }
  };

  const handleModalAccount = async function () {
    const user = await Auth.currentAuthenticatedUser();

    setModal({
      data: {
        frmSetPreferredMFA: {
          mfa: {
            SMS_MFA: 'SMS',
            SOFTWARE_TOKEN_MFA: 'TOTP',
          }[user?.preferredMFA] || 'NOMFA',
          phone_number: user?.attributes?.phone_number,
        },
        frmUserToken: user,
      },
      events: {
        onClose: () => setModal(),
      },
      open: true,
    });
  };

  const handleInit = async function () {
    const user = await Auth.currentAuthenticatedUser();

    setCurrentUser({
      label: [
        user?.attributes?.name || '',
        user?.attributes?.family_name || '',
      ]
        .filter((item) => !!item)
        .join(' ')
        || user?.attributes?.phone_number
        || user?.attributes?.email
        || user?.username
        || 'Usuario',
    });
  };

  const menuOptions = [
    {
      hidden: !hasPermissions('company.select.getCompany'),
      icon: <BusinessIcon fontSize="small" />,
      label: 'Compañia',
      fn: () => history.push('/settings/company'),
    },
    {
      icon: <VpnLockIcon fontSize="small" />,
      label: 'Cuenta',
      fn: handleModalAccount,
    },
    {
      type: 'divider',
    },
    {
      icon: <ExitToAppIcon fontSize="small" />,
      label: 'Cerrar sesión',
      fn: handleLogout,
    },
  ];

  useEffect(() => {
    handleInit();
  }, []);

  return (
    <>
      <AccountModal {...modal} />

      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar alt="User" className={classes.avatar} />
        {currentUser && (<Hidden smDown>
          <Typography
            children={currentUser?.label}
            color="inherit"
            variant="h6"
          />
          {isOpen
            ? <ArrowDropUpIcon className={classes.avatarArrow} />
            : <ArrowDropDownIcon className={classes.avatarArrow} />
          }
        </Hidden>)}
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{
          className: classes.popover,
        }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        {menuOptions.filter(item => !item.hidden).map((item, index) => {

          if (item.type === 'divider') {
            return (<Divider />);
          }

          return (<MenuItem
            children={<>
              <ListItemIcon children={item.icon} /> {item.label}
            </>}
            key={index}
            onClick={() => {
              handleClose();

              if (typeof item.fn === 'function') {
                item.fn();
              }
            }}
          />);
        })}
      </Menu>
    </>
  );
}

export default AccountMenuControl;
