import { lazy } from 'react';

const settingsTemplatesRoutes = (parentPath) => [
  {
    exact: true,
    path: `${parentPath}/people`,
    permission: 'clientTypes.select.getClientTypes',
    component: lazy(() => import('views/Settings/Templates/People/List')),
  },
  {
    exact: true,
    path: `${parentPath}/people/add`,
    permission: 'clientTypes.insert.addClientTypes',
    component: lazy(() => import('views/Settings/Templates/People/Edit')),
  },
  {
    exact: true,
    path: `${parentPath}/people/:peopleTypeId`,
    permission: 'clientTypes.select.getClientTypes',
    component: lazy(() => import('views/Settings/Templates/People/Edit')),
  },
  {
    exact: true,
    path: `${parentPath}/products`,
    permission: 'productTypes.select.listProductTypes',
    component: lazy(() => import('views/Settings/Templates/Products/List')),
  },
  {
    exact: true,
    path: `${parentPath}/products/add`,
    permission: 'productTypes.insert.addProductTypes',
    component: lazy(() => import('views/Settings/Templates/Products/Edit')),
  },
  {
    exact: true,
    path: `${parentPath}/products/:productId`,
    permission: 'productTypes.select.getProductTypes',
    component: lazy(() => import('views/Settings/Templates/Products/Edit')),
  },
  {
    exact: true,
    path: `${parentPath}/notifications`,
    permission: 'notificationTypes.select.listNotificationTypes',
    component: lazy(() => import('views/Settings/Templates/Notifications/List')),
  },
  {
    exact: true,
    path: `${parentPath}/notifications/add`,
    permission: 'notificationTypes.insert.addNotificationTypes',
    component: lazy(() => import('views/Settings/Templates/Notifications/Edit')),
  },
  {
    exact: true,
    path: `${parentPath}/notifications/:templateId`,
    permission: 'notificationTypes.select.getNotificationTypes',
    component: lazy(() => import('views/Settings/Templates/Notifications/Edit')),
  },
];

export default settingsTemplatesRoutes;
