import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  Box,
  Tab,
  Tabs,
} from '@material-ui/core';

import {
  DynamicForm,
  Modal,
} from 'components';
import { changePasswordSchema } from 'validations/schema/forms';

export const TAB = {
  ACCOUNT_INFORMATION: 'accountInformation',
  CHANGE_PASSWORD: 'changePassword',
  CURRENT_SESSION: 'currentSession',
  PREFERRED_MFA: 'preferredMFA',
};

export const TABS = [
  {
    hidden: !(process.env.NODE_ENV === 'development'),
    label: 'Información',
    value: TAB.ACCOUNT_INFORMATION,
  },
  {
    label: 'Cambiar contraseña',
    value: TAB.CHANGE_PASSWORD,
  },
  {
    label: 'Configurar MFA',
    value: TAB.PREFERRED_MFA,
  },
  {
    hidden: !(process.env.NODE_ENV === 'development'),
    label: 'Sessión actual',
    value: TAB.CURRENT_SESSION,
  },
];

function AccountModal(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [currentTab, setCurrentTab] = useState(
    [...TABS].shift().value,
  );
  const [formValues, setFormValues] = useState({});
  const [hasSentCode, setHasSentCode] = useState(false);
  const { t } = useTranslation();

  const actions = [
    {
      color: 'error',
      hidden: !(currentTab === TAB.PREFERRED_MFA && hasSentCode),
      label: 'Cancelar',
      left: true,
      fn: () => setHasSentCode(false),
    },
    {
      color: 'primary',
      form: 'frmSetPreferredMFA',
      hidden: !(currentTab === TAB.PREFERRED_MFA),
      label: hasSentCode
        ? 'Validar y activar'
        : {
          SMS: 'Enviar código',
          TOTP: 'Iniciar verificación',
        }[formValues?.mfa] || 'Guardar',
      type: 'submit',
    },
    {
      color: 'primary',
      form: 'frmUpdateUserPassword',
      hidden: !(currentTab === TAB.CHANGE_PASSWORD),
      label: 'Guardar cambio',
      type: 'submit',
    },
  ];

  const handleChangePreferredMFA = async function (values) {
    const { code, mfa, phone_number } = values;
    let message, variant = 'success';

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.setPreferredMFA(user, 'NOMFA');

      switch (mfa) {
        default:
        case 'NOMFA': {
          if (props.events?.onClose) {
            props.events.onClose();
          }

          message = 'MFA esta desactivado';

          break;
        }

        case 'SMS': {
          if (hasSentCode) {
            await Auth.verifyCurrentUserAttributeSubmit('phone_number', code);
            await Auth.setPreferredMFA(user, mfa);

            setHasSentCode(false);

            if (props.events?.onClose) {
              props.events.onClose();
            }

            message = 'MFA mediante mensaje de texto seleccionado';
          } else {
            await Auth.updateUserAttributes(user, {
              phone_number,
            });
            await Auth.verifyCurrentUserAttribute('phone_number');

            setHasSentCode(true);

            message = 'Código de verificación enviado';
          }

          break;
        }

        case 'TOTP': {
          if (hasSentCode) {
            await Auth.verifyTotpToken(user, code);
            await Auth.setPreferredMFA(user, mfa);

            setHasSentCode(false);

            if (props.events?.onClose) {
              props.events.onClose();
            }

            message = 'MFA mediante aplicación seleccionado';
          }
          else {
            const totp = await Auth.setupTOTP(user);
            setFormValues({
              ...formValues,
              username: user.username,
              totp,
            });
            setHasSentCode(true);

            message = 'Iniciado proceso de verificación';
          }

          break;
        }
      }
    } catch (error) {
      message = `auth.${error.code || 'CanNotChangePreferredMfa'}`;
      variant = 'error';
    } finally {
      enqueueSnackbar(t(message), {
        variant,
      });
    }
  };

  const handleChangePassword = async function ({ newPassword, oldPassword }) {
    let message, variant = 'success';

    try {
      const user = await Auth.currentAuthenticatedUser();

      await Auth.changePassword(user, oldPassword, newPassword);

      if (props.events?.onClose) {
        props.events.onClose();
      }
    } catch (error) {
      message = `auth.${error.code || 'CanNotChangePassword'}`;
      variant = 'error';
    } finally {
      enqueueSnackbar(t(message), {
        variant,
      });
    }
  };

  useEffect(() => {
    setFormValues(props?.data?.frmSetPreferredMFA || {});
  }, [props]);

  return (<Modal actions={actions} maxWidth="sm" {...props}>
    <>
      <Tabs
        onChange={(e, value) => setCurrentTab(value)}
        scrollButtons="auto"
        textColor="secondary"
        value={currentTab}
        variant="scrollable"
      >
        {TABS.filter(item => !item.hidden).map((item, index) => (
          <Tab key={index} {...item} />
        ))}
      </Tabs>

      <Box mt={2}>

        {currentTab === TAB.ACCOUNT_INFORMATION && (<DynamicForm
          fields={[
            {
              label: 'Correo electrónico',
              name: 'email',
            },
            {
              label: 'Número de teléfono',
              name: 'phone_number',
              settings: {
                format: 'mx_phone',
              },
              type: 'number',
            },
          ]}
        />)}

        {currentTab === TAB.PREFERRED_MFA && (<DynamicForm
          fields={[
            {
              disabled: hasSentCode,
              events: {
                onChange: function (target) {
                  setFormValues({
                    ...formValues,
                    [target.name]: target.value,
                  });
                },
              },
              label: 'Tipo',
              name: 'mfa',
              settings: {
                options: [
                  {
                    id: 'NOMFA',
                    name: 'Sin MFA',
                  },
                  {
                    id: 'TOTP',
                    name: 'Aplicación de autenticación',
                  },
                  {
                    id: 'SMS',
                    name: 'Mensaje de texto',
                  },
                ],
              },
              type: 'select',
            },
            {
              disabled: hasSentCode,
              label: 'Número de teléfono',
              name: 'phone_number',
              settings: {
                format: 'phone_number',
              },
              type: formValues.mfa === 'SMS'
                ? 'number'
                : 'hidden',
            },
            {
              label: 'Escanea el código con la aplicación de autenticación',
              type: (formValues.mfa === 'TOTP' && hasSentCode)
                ? 'divider'
                : 'hidden',
            },
            {
              label: (<Box m={1} textAlign="center">
                <QRCode
                  size={200}
                  value={[
                    'otpauth://totp/AWSCognito:',
                    formValues?.username,
                    '?secret=',
                    formValues?.totp,
                    '&issuer=DynamiCore',
                  ].join('')}
                />
              </Box>),
              type: (formValues.mfa === 'TOTP' && hasSentCode)
                ? 'description'
                : 'hidden',
            },
            {
              label: `Ingresa el código ${{
                SMS: 'recibido por mensaje de texto',
                TOTP: 'generado en la aplicación de autenticación',
              }[formValues?.mfa]}`,
              type: hasSentCode
                ? 'divider'
                : 'hidden',
            },
            {
              name: 'code',
              type: hasSentCode
                ? 'splitted'
                : 'hidden',
            },
          ]}
          id="frmSetPreferredMFA"
          initialValues={props?.data?.frmSetPreferredMFA || {}}
          handleSubmitData={handleChangePreferredMFA}
        />)}

        {currentTab === TAB.CHANGE_PASSWORD && (<DynamicForm
          fields={[
            {
              label: 'Contraseña actual',
              name: 'oldPassword',
            },
            {
              label: 'Contraseña nueva',
              name: 'newPassword',
            },
          ].map(field => ({
            label: field.label,
            name: field.name,
            type: 'password',
            display: {
              breakpoints: {
                md: 12,
                sm: 12,
                xs: 12,
              },
            },
            settings: {
              canReveal: true,
            },
          }))}
          id="frmUpdateUserPassword"
          handleSubmitData={handleChangePassword}
          validationSchema={changePasswordSchema}
        />)}

        {currentTab === TAB.CURRENT_SESSION && (<DynamicForm
          fields={[
            {
              label: 'username',
              name: 'payload.username',
            },
            {
              label: 'sub',
              name: 'payload.sub',
            },
            {
              label: 'jwtToken',
              name: 'jwtToken',
              type: 'textarea',
              settings: {
                multiline: true,
                rows: 3,
                rowsMax: 5,
              },
            },
          ]}
          initialValues={{
            ...props?.data?.frmUserToken?.signInUserSession?.accessToken,
          }}
        />
        )}
      </Box>

    </>
  </Modal >);
}

AccountModal.defaultProps = {
  events: {},
  open: false,
  title: 'Configurar cuenta',
};

AccountModal.propTypes = {
  data: PropTypes.shape({
    frmSetPreferredMFA: PropTypes.object,
    frmUserToken: PropTypes.object,
  }),
  events: PropTypes.shape({
    onClose: PropTypes.func,
  }),
  open: PropTypes.bool,
  title: PropTypes.string,
};

export default AccountModal;
