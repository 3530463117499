import * as Yup from 'yup';

import MESSAGE from 'validations/constants/messages';

const configSchema = Yup
  .object()
  .shape({
    columns: Yup
      .object()
      .default({}),
    filters: Yup
      .object()
      .default({}),
    params: Yup
      .object()
      .default({})
      .shape({
        client_type: Yup
          .number()
          .positive()
          .integer(),
        product: Yup
          .number()
          .positive()
          .integer(),
      }),
  });

export const reportsFormSchema = Yup
  .object()
  .shape({
    id: Yup
      .number()
      .positive()
      .integer(),
    name: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    description: Yup
      .string()
      .required(MESSAGE.REQUIRED),
    config: configSchema,
  });
