import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

import {
  Header,
  Page,
} from 'components';

function Error404View() {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (<Page maxWidth="lg" title="403: Forbidden">

    <Header />

    <Box mt={3}>
      <Card>
        <CardContent>
          <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'} color="textPrimary">
            403: Access to this resource on the server is denied!
          </Typography>

          <Box mt={6} display="flex" justifyContent="center">
            <Button
              children="Back to home"
              color="secondary"
              component={RouterLink}
              to="/"
              variant="outlined"
            />
          </Box>
        </CardContent>
      </Card>
    </Box>

  </Page>);
}

export default Error404View;
