import { lazy } from 'react';

const settingsFlowsRoutes = (parentPath) => [
  {
    exact: true,
    path: `${parentPath}`,
    permission: 'flows.select.listFlows',
    component: lazy(() => import('views/Settings/Flows/List')),
  },
  {
    exact: true,
    path: `${parentPath}/add`,
    permission: 'flows.insert.addFlows',
    component: lazy(() => import('views/Settings/Flows/Edit')),
  },
  {
    exact: true,
    path: `${parentPath}/:flowId`,
    permission: 'flows.select.getFlows',
    component: lazy(() => import('views/Settings/Flows/Edit')),
  },
];

export default settingsFlowsRoutes;
