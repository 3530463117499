/* eslint-disable use-isnan */
import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem } from '@material-ui/core';

import { DEFAULT_LOCALE_EN } from '../locale';
import { classNames, sort } from '../utils';
import {
  parsePartArray,
  partToString,
  formatValue,
} from '../converter';

function CustomSelect(props) {
  const {
    value,
    setValue,
    locale,
    className,
    humanizeLabels,
    disabled,
    readOnly,
    leadingZero,
    clockFormat,
    optionsList,
    unit,
  } = props;
  const stringValue = useMemo(() => {
    if (value && Array.isArray(value)) {
      return value.map(value => value.toString());
    }

    return [];
  }, [value]);
  const options = useMemo(() => {
    if (optionsList) {
      return optionsList.map((option, index) => {
        const number = unit.min === 0 ? index : index + 1;
        return {
          value: number.toString(),
          label: option,
        };
      });
    }

    return [...Array(unit.total)].map((e, index) => {
      const number = unit.min === 0 ? index : index + 1;
      return {
        value: number.toString(),
        label: formatValue(number, unit, humanizeLabels, leadingZero, clockFormat),
      };
    });
  }, [optionsList, leadingZero, humanizeLabels, clockFormat]);
  const localeJSON = JSON.stringify(locale);
  const renderTag = useCallback(props => {
    const value = props;

    if (!value || Number(value[0]) === NaN) {
      return React.createElement(React.Fragment, null);
    }

    const parsedArray = parsePartArray(value, unit);
    const cronValue = partToString(parsedArray, unit, humanizeLabels, leadingZero, clockFormat);
    const testEveryValue = cronValue.match(/^\*\/([0-9]+),?/) || [];
    return React.createElement('div', null, testEveryValue[1] ? `${locale.everyText || DEFAULT_LOCALE_EN.everyText}
            ${testEveryValue[1]}` : cronValue);
  }, [value, localeJSON, humanizeLabels, leadingZero, clockFormat]);
  const simpleClick = useCallback(event => {
    let newValueOption = event.target.value;

    if (newValueOption.length === 0) {
      newValueOption.push(0);
    }

    newValueOption = Array.isArray(newValueOption) ? sort(newValueOption) : [newValueOption];
    const newValue = newValueOption;

    if (newValue.length === unit.total) {
      setValue([]);
    } else {
      setValue(newValue);
    }
  }, [setValue, value]);
  const internalClassName = useMemo(() => classNames({
    'react-js-cron-select': true,
    'react-js-cron-custom-select': true,
    [`${className}-select`]: !!className,
  }), [className]);

  return React.createElement(Select, {
    multiple: true,
    variant: 'outlined',
    open: readOnly ? false : undefined,
    value: stringValue,
    onChange: simpleClick,
    renderValue: renderTag,
    className: internalClassName,
    autoWidth: false,
    disabled: disabled,
  }, options.map(obj => React.createElement(MenuItem, {
    key: obj.value,
    value: obj.value,
  }, obj.label)));
}

CustomSelect.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.func,
  locale: PropTypes.any,
  className: PropTypes.any,
  humanizeLabels: PropTypes.any,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  leadingZero: PropTypes.any,
  clockFormat: PropTypes.any,
  optionsList: PropTypes.any,
  unit: PropTypes.any,
};

export default CustomSelect;
