import * as Yup from 'yup';

import MESSAGE from 'validations/constants/messages';

const validateSchema = Yup
  .object()
  .shape({
    max: Yup
      .number(),
    min: Yup
      .number(),
    required: Yup
      .boolean()
      .default(false),
  });

export const configSchema = Yup
  .object()
  .shape({
    disabled: Yup
      .boolean()
      .default(false),
    displayname: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    displaytype: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    name: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    options: Yup
      .array(),
    score: Yup
      .number()
      .integer()
      .positive(),
    type: Yup
      .string()
      .required(MESSAGE.REQUIRED),
    validate: validateSchema,
    value: Yup
      .mixed()
      .optional(),
  });

export const operationSchema = Yup
  .object()
  .shape({
    id: Yup
      .number()
      .integer()
      .positive()
      .optional(),
    name: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    operation: Yup
      .number()
      .integer()
      .positive()
      .required(MESSAGE.REQUIRED)
      .typeError(MESSAGE.TYPE_ERROR_NUMBER),
    product: Yup
      .number()
      .integer()
      .positive(),
  });

export const templateSchema = Yup
  .object()
  .shape({
    displayname: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    displaytype: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    field: Yup
      .number()
      .integer()
      .positive()
      .optional(),
    format: Yup
      .string()
      .optional(),
    name: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    options: Yup
      .array(),
    score: Yup
      .number()
      .integer()
      .positive(),
    type: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    validate: validateSchema,
  });

export const productsFormSchema = Yup
  .object()
  .shape({
    id: Yup
      .number()
      .integer()
      .positive()
      .optional(),
    name: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    description: Yup
      .string()
      .trim()
      .required(MESSAGE.REQUIRED),
    product_type: Yup
      .number()
      .integer()
      .positive(),
    config: Yup
      .array()
      .of(configSchema)
      .default([]),
    tabs: Yup
      .object()
      .default({}),
    type: Yup
      .string()
      .oneOf([
        'assets',
        'liabilities',
      ])
      .required(MESSAGE.REQUIRED),
    currency: Yup
      .number()
      .integer()
      .positive()
      .required(MESSAGE.REQUIRED),
    operations: Yup
      .array()
      .of(operationSchema)
      .default([]),
    template: Yup
      .array(templateSchema)
      .default([]),
  });
