import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';

import { createElement } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify';
import { enableES5 } from 'immer';

import { SettingsProvider } from 'context/SettingsContext';
import { configureStore } from 'redux/store';
import { AWS_AMPLIFY } from 'settings/aws';
import { restoreSettings } from 'utils/settings';

import App from './App';
import './locales';

enableES5();

const store = configureStore();
const settings = restoreSettings();

Amplify.configure(AWS_AMPLIFY);

if (navigator && navigator.serviceWorker) {
  navigator
    .serviceWorker
    .getRegistrations()
    .then(function (registrations) {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
}

ReactDOM.render(
  createElement(Provider, {
    children: createElement(SettingsProvider, {
      children: createElement(App),
      settings,
    }),
    store,
  }),
  document.getElementById('root'),
);
