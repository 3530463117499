import { Auth } from 'aws-amplify';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
} from 'redux/constants/auth';

export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

export const loginSuccess = () => {
  return {
    type: LOGIN_SUCCESS,
  };
};

export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    error,
  };
};

export const logOut = () => {
  return {
    type: LOGOUT,
  };
};

export const signIn = (email, pass) => {
  return async (dispatch) => {
    dispatch(loginRequest());
    try {
      let user = await Auth.signIn(email, pass);
      if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await Auth.completeNewPassword(user, pass);
        user = await Auth.signIn(email, pass);
      }

      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('theme', 'COMPANY_A55');

      dispatch(loginSuccess());

      return user?.signInUserSession?.accessToken.jwtToken || '';
    } catch (error) {
      dispatch(loginFailure(error.message));
      throw error;
    }
  };
};

export const logOutCompletely = () => {
  return async (dispatch) => {
    await Auth.signOut({
      global: true,
    });
    localStorage.clear();
    dispatch(logOut());
  };
};
