import * as Yup from 'yup';

import MESSAGE from '../../constants/messages';

export const appMappingSchema = Yup
  .object()
  .shape({
    client_type: Yup
      .number()
      .integer()
      .positive(),
    map: Yup
      .object()
      .default({}),
  });

export const appMappingFormSchema = Yup
  .object()
  .shape({
    mapping: Yup
      .array()
      .of(appMappingSchema)
      .default([])
      .required(MESSAGE.REQUIRED),
    type: Yup
      .string()
      .required(MESSAGE.REQUIRED)
      .trim()
      .lowercase(),
  });
