import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
} from '@material-ui/core';
import arrowRight from './images/arrow-right.svg';

function ButtonControl(props) {
  const {
    color,
    form,
    label,
    processing,
    type,
  } = props;

  return (
    <Button
      color={color}
      disabled={processing}
      form={form}
      fullWidth
      size="large"
      startIcon={processing ? <CircularProgress
        size={18}
        style={{
          marginRight: 5,
        }}
      /> : undefined}
      type={type}
      variant="contained"
    >
      <div style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <div
          children={label}
          style={{
            textAlign: 'left',
          }}
        />
        <div><img src={arrowRight} alt={label} /></div>
      </div>
    </Button>

  );
}

ButtonControl.propTypes = {
  color: PropTypes.string,
  form: PropTypes.string,
  label: PropTypes.string,
  processing: PropTypes.bool,
  type: PropTypes.string,
};

export default ButtonControl;
