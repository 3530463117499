import PropTypes from 'prop-types';

export const DEFAULT_PROPS = {
  actions: [],
  config: {},
  events: {},
  headers: [],
  rows: [],
};

export const PROP_TYPES = {
  actions: PropTypes.array,
  config: PropTypes.shape({
    filter: PropTypes.bool,
    offsetTop: PropTypes.number,
    pagination: PropTypes.bool,
    responsive: PropTypes.oneOf([
      'simple',
      'standard',
      'vertical',
    ]),
    storageKey: PropTypes.string,
  }),
  customSearch: PropTypes.func,
  events: PropTypes.shape({
    onDownload: PropTypes.func,
    onFilterChange: PropTypes.func,
    onFilterChipClose: PropTypes.func,
    onFilterConfirm: PropTypes.func,
    onTableChange: PropTypes.func,
    onTableInit: PropTypes.func,
    onViewColumnsChange: PropTypes.func,
  }),
  headers: PropTypes.arrayOf(PropTypes.shape({
    align: PropTypes.string,
    label: PropTypes.string,
    format: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      name: PropTypes.string,
    })),
    type: PropTypes.string,
    variant: PropTypes.shape({
      type: PropTypes.oneOf([
        'component',
      ]),
      mapping: PropTypes.oneOf([
        'label',
        'link',
      ]),
      props: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
      ]),
    }),
  })),
  rows: PropTypes.array,
  title: PropTypes.string,
};

export const TEXT_LABELS = {
  body: {
    noMatch: 'Lo sentimos, no se encontraron registros coincidentes',
    toolTip: 'Ordenar',
  },
  filter: {
    all: 'Todos',
    title: 'Filtros',
    reset: 'Restaurar',
  },
  pagination: {
    displayRows: 'de',
    jumpToPage: 'Ir a la página',
    next: 'Siguiente',
    previous: 'Anterior',
    rowsPerPage: 'Filas por página',
  },
  selectedRows: {
    text: 'filas seleccionadas',
    delete: 'Eliminar',
    deleteAria: 'Eliminar filas seleccionadas',
  },
  toolbar: {
    downloadCsv: 'Descargar',
    filterTable: 'Filtrar tabla',
    print: 'Imprimir',
    search: 'Buscar',
    viewColumns: 'Ver columnas',
  },
  viewColumns: {
    title: 'Ver columnas',
    titleAria: 'Mostrar/Ocultar columnas de la tabla',
  },
};
