import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { LoadingScreen } from 'components';
import { hasPermissions } from 'utils/permissions';
import Error403View from 'views/Errors/Error403';

function PermissionGuard({ permission, children }) {
  const [node, setNode] = useState(<LoadingScreen />);

  useEffect(() => {
    const Component = !!!permission || hasPermissions(permission)
      ? children
      : <Error403View permission={permission} />;

    setNode(Component);
  }, []);

  return node;
};

PermissionGuard.propTypes = {
  children: PropTypes.node,
  permission: PropTypes.string,
};

export default PermissionGuard;
