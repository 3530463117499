import _ from 'lodash';
import {
  colors,
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core';
import { esES as esLocale } from '@material-ui/core/locale';

import { THEMES } from 'constants/index';
import typography from './typography';
import { softShadows, strongShadows } from './shadows';

function readTheme() {
  let theme;
  try {
    const company = JSON.parse(localStorage.getItem('company'));

    theme = company?.theme || {};
  } catch (e) {
    theme = {};
  } finally {
    return theme;
  }
}

const companyTheme = readTheme();

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'unset !important',
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
    MUIDataTableBodyRow: {
      root: {
        '&:nth-child(even)': {
          backgroundColor: 'rgba(160, 160, 160, 0.075)',
        },
        '&:hover': {
          backgroundColor: '#e0e0e0 !important',
        },
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'unset !important',
      },
    },
  },
};

export function createTheme(settings = {}) {
  const themeConfigs = [
    {
      name: THEMES.LIGHT,
      overrides: {
        MuiTableCell: {
          head: {
            padding: '6px !important',
            backgroundColor: `${companyTheme.secondary || '#0C3A72'} !important`,
            color: `${colors.common.white} !important`,
            '& .MuiButton': {
              color: `${colors.common.white} !important`,
            },
          },
        },
        MuiInputBase: {
          input: {
            '&::placeholder': {
              opacity: 1,
              color: colors.blueGrey[600],
            },
          },
        },
        MUIDataTableHeadCell: {
          contentWrapper: {
            width: '100% !important',
            display: 'block',
            alignItems: 'center',
          },
          dragCursor: {
            width: '100%',
          },
          sortAction: {
            alignItems: 'center',
            backgroundColor: 'aqua',
            cursor: 'pointer',
            display: 'contents',
            justifyContent: 'left',
            width: '100% !important',
          },
          toolButton: {
            width: '100%',
            marginLeft: '0',
            marginRight: '0',
          },
        },
        MuiButton: {
          label: {
            '& span': {
              textAlign: 'left !important',
            },
          },
        },
      },
      palette: {
        type: 'light',
        action: {
          active: colors.blueGrey[600],
        },
        background: {
          default: colors.common.white,
          dark: '#f4f6f8',
          paper: colors.common.white,
        },
        primary: {
          main: settings.primary || companyTheme.primary || '#182233',
        },
        secondary: {
          main: settings.secondary || companyTheme.secondary || '#0C3A72',
        },
        text: {
          primary: colors.blueGrey[900],
          secondary: colors.blueGrey[600],
        },
      },
      shadows: softShadows,
    },
    {
      name: THEMES.ONE_DARK,
      palette: {
        type: 'dark',
        action: {
          active: 'rgba(255, 255, 255, 0.54)',
          hover: 'rgba(255, 255, 255, 0.04)',
          selected: 'rgba(255, 255, 255, 0.08)',
          disabled: 'rgba(255, 255, 255, 0.26)',
          disabledBackground: 'rgba(255, 255, 255, 0.12)',
          focus: 'rgba(255, 255, 255, 0.12)',
        },
        background: {
          default: '#282C34',
          dark: '#1c2025',
          paper: '#282C34',
        },
        primary: {
          main: '#8a85ff',
        },
        secondary: {
          main: '#8a85ff',
        },
        text: {
          primary: '#e6e5e8',
          secondary: '#adb0bb',
        },
      },
      shadows: strongShadows,
    },
    {
      name: THEMES.UNICORN,
      palette: {
        type: 'dark',
        action: {
          active: 'rgba(255, 255, 255, 0.54)',
          hover: 'rgba(255, 255, 255, 0.04)',
          selected: 'rgba(255, 255, 255, 0.08)',
          disabled: 'rgba(255, 255, 255, 0.26)',
          disabledBackground: 'rgba(255, 255, 255, 0.12)',
          focus: 'rgba(255, 255, 255, 0.12)',
        },
        background: {
          default: '#2a2d3d',
          dark: '#222431',
          paper: '#2a2d3d',
        },
        primary: {
          main: '#a67dff',
        },
        secondary: {
          main: '#a67dff',
        },
        text: {
          primary: '#f6f5f8',
          secondary: '#9699a4',
        },
      },
      shadows: strongShadows,
    },
  ];

  let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme);

  if (!themeConfig) {
    [themeConfig] = themeConfigs;
  }

  let theme = createMuiTheme(
    _.merge(
      {},
      baseConfig,
      themeConfig,
      {
        direction: settings.direction,
      },
    ),
    esLocale,
  );

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
