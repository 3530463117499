import { lazy } from 'react';

const cardsRoutes = (parentPath) => [
  {
    exact: true,
    path: `${parentPath}`,
    permission: 'clients.select.listClients',
    component: lazy(() => import('views/Cards/List')),
  },
  {
    exact: true,
    path: `${parentPath}/:cardToken/:lastFour/:external_id`,
    permission: 'clients.select.getClients',
    component: lazy(() => import('views/Cards/Details')),
  },
];

export default cardsRoutes;
